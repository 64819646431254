import { useAppSelector } from '@/common';
import { LeftArrowIcon } from '@/common/assets/icons';
import { NavLink, useParams } from 'react-router-dom';
import Dashboard from '../dashboard';
import { selectDashboardTemplate } from '@/stores/dashboardTemplates';
import NewDashboardForm from '@/modules/dashboards/templatePreview/NewDashboardForm';

const TemplatePreview = (): JSX.Element => {
  const { storeId = '', templateId = '' } = useParams();
  const selectedTemplate = useAppSelector(selectDashboardTemplate(templateId ?? ''));

  return (
    <div className='grid grid-cols-12 gap-x-12'>
      <div className='col-span-8 2xl:col-span-9 6xl:col-span-10'>
        <NavLink className='min-w-0 flex-auto' to='..'>
          <div className='flex items-center gap-x-3 text-2xl text-[#666666]'>
            <LeftArrowIcon className='fill-emphasis-primary' /> <span className='font-semibold'>Configure</span> -
            <span className='font-light'> {selectedTemplate?.name}</span>
          </div>
        </NavLink>
        <div className='pt-5'>
          <Dashboard />
        </div>
      </div>
      <div className='col-span-4 2xl:col-span-3 6xl:col-span-2'>
        <NewDashboardForm storeId={storeId} templateId={templateId} />
      </div>
    </div>
  );
};

export default TemplatePreview;
