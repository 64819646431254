import React, { useCallback, useMemo } from 'react';
import { UseFieldArrayRemove } from 'react-hook-form';
import { ConditionTypes, TriggerOption, type Condition } from '../../../../stores/triggers/types';
import TimeInRoi from './TimeInRoi';
import ChipBayPercentage from './ChipBayPercentage';
import { conditionTypes } from '../../common';
import TableStatus from './TableStatus';
import NewItemPicker from '../../components/NewItemPicker';

interface TriggerConditionsProps {
  conditionFields: Condition[];
  removeCondition: UseFieldArrayRemove;
  addCondition: (type: string) => void;
  roiIds: TriggerOption[];
  chipBayIds: TriggerOption[];
  tableIds: TriggerOption[];
}

const TriggerConditions: React.FC<TriggerConditionsProps> = ({ conditionFields, removeCondition, addCondition, roiIds, chipBayIds, tableIds }) => {
  const filterdConditionTypes = useMemo(() => {
    return conditionTypes.filter((item: any) => {
      if (item.value === ConditionTypes.CHIP_BAY_PERCENTAGE) {
        return chipBayIds.length > 0;
      }
      if (item.value === ConditionTypes.TABLE_STATUS) {
        return tableIds.length > 0;
      }
      if (item.value === ConditionTypes.TIME_IN_ROI) {
        return roiIds.length > 0;
      }
      return true;
    });
  }, [conditionTypes, chipBayIds, tableIds, roiIds]);

  const renderCondition = useCallback(
    (condition: any, index: number) => {
      switch (condition.metric) {
        case ConditionTypes.TIME_IN_ROI:
          return <TimeInRoi key={index} index={index} removeCondition={removeCondition} roiIds={roiIds} />;
        case ConditionTypes.TABLE_STATUS:
          return <TableStatus key={index} index={index} removeCondition={removeCondition} tableIds={tableIds} />;
        case ConditionTypes.CHIP_BAY_PERCENTAGE:
          return <ChipBayPercentage key={index} index={index} removeCondition={removeCondition} chipBayIds={chipBayIds} />;
        default:
          return null;
      }
    },
    [conditionFields, removeCondition, addCondition],
  );

  return (
    <div className='flex border-t border-emphasis-base'>
      <div className='flex relative w-12'>
        <h3 className='mt-5 text-lg font-semibold text-emphasis-primary'>If</h3>
      </div>
      <div className='flex flex-col'>
        {conditionFields.length !== 0 && (
          <div className='flex flex-col mt-8'>{conditionFields.map((condition: any, index: number) => renderCondition(condition, index))}</div>
        )}
        <div className='flex space-x-4'>
          <div className='flex flex-row w-12'></div>
          <div className={`flex flex-col w-full pb-4 ${conditionFields.length !== 0 && 'border-t border-emphasis-base'}`}>
            <NewItemPicker numberOfItems={conditionFields.length} items={filterdConditionTypes} handleAddItem={addCondition} type='condition' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TriggerConditions;
