interface HeaderProps {
  children: JSX.Element | JSX.Element[];
}

const Header = ({ children }: HeaderProps): JSX.Element => {
  return (
    <div className='mb-16'>
      <div className='fixed top-0 left-20 5xl:left-72 right-0 h-16 px-6 bg-white shadow z-40'>
        <div className='flex items-center h-full'>{children}</div>
      </div>
    </div>
  );
};

export const Title = ({ children }: { children: JSX.Element | string }): JSX.Element => {
  return <span className='text-2xl font-light text-emphasis-secondary'>{children}</span>;
};

export default Header;
