import { type FieldValues, useFormContext } from 'react-hook-form';
import DialogWrapper, { CloseButton, ModalContent, ModalHeader, ModalTitle } from '@/modules/components/dialogWrapper';
import { ContainedButton, TextButton } from '@/modules/components/form/Buttons';
import React from 'react';
import { type ConfirmDialogProps } from '@/modules/components/confirmDialog';
import { useJitsu } from '@jitsu/jitsu-react';
import { camelCase } from 'lodash';

interface OptionConfirmDialogProps extends ConfirmDialogProps {
  children: JSX.Element | JSX.Element[];
  title: string;
  cancelText?: string;
  confirmText?: string;
}

const OptionConfirmDialog = (props: OptionConfirmDialogProps): JSX.Element => {
  const { reject, confirm, close, children, title, cancelText = 'Cancel', confirmText = 'Apply', ...rest } = props;
  const { analytics } = useJitsu();

  const { handleSubmit } = useFormContext();

  return (
    <DialogWrapper {...rest}>
      <ModalHeader>
        <ModalTitle>{title}</ModalTitle>
        <div className='flex flex-1 justify-end'>
          <CloseButton
            onClick={(e) => {
              e.preventDefault();
              close();
            }}
          />
        </div>
      </ModalHeader>
      <ModalContent>
        <form
          className='grid grid-cols-1 gap-y-16 content-evenly text-emphasis-secondary min-w-96'
          onSubmit={handleSubmit(async (data: FieldValues): Promise<void> => {
            confirm(data);
            analytics.track(`targets_${camelCase(title)}`);
          })}
        >
          <div className='space-y-5'>{children}</div>
          <div className='flex items-center justify-center gap-x-16'>
            <TextButton
              onClick={(e) => {
                e.preventDefault();
                reject(false);
              }}
            >
              {cancelText}
            </TextButton>
            <ContainedButton>{confirmText}</ContainedButton>
          </div>
        </form>
      </ModalContent>
    </DialogWrapper>
  );
};

export default OptionConfirmDialog;
