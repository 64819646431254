import { type TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { type AppDispatch, type RootState } from '@/stores';
import { type Store, selectStore } from '@/stores/clientConfigs';
import { useParams } from 'react-router-dom';
import { reduce } from 'lodash';
import { type LDClient } from 'launchdarkly-js-client-sdk';
import { type ForwardedRef, useEffect, useRef, useMemo } from 'react';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useCurrentStore = (): Store | undefined => {
  const { storeId = '' } = useParams();
  return useAppSelector(useMemo(() => selectStore(storeId), [storeId]));
};

export const useForwardRef = <T>(ref: ForwardedRef<T>, initialValue: any = null): React.MutableRefObject<T> => {
  const targetRef = useRef<T>(initialValue);

  useEffect(() => {
    if (ref === null || ref === undefined) return;

    if (typeof ref === 'function') {
      ref(targetRef.current);
    } else {
      ref.current = targetRef.current;
    }
  }, [ref]);

  return targetRef;
};

// This will return true for following cases: undefined, null, "", {}, []
export const isEmpty = (value: any): boolean => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'string' && value.trim() === '') ||
    (typeof value === 'object' && Object.keys(value).length === 0)
  );
};

export const getDirtyFormData = <TData extends Record<keyof TDirtyItems, unknown>, TDirtyItems extends Record<string, unknown>>(
  data: TData,
  dirtyFields: TDirtyItems,
): Partial<TData> => {
  return reduce(
    dirtyFields,
    (dirtyData, value, name) => {
      if (value === true) {
        return { ...dirtyData, [name]: data[name] };
      } else if (typeof value === 'object' || Array.isArray(value)) {
        // Recursively get dirty form data
        return {
          ...dirtyData,
          [name]: getDirtyFormData(data[name] as TData, dirtyFields[name] as TDirtyItems),
        };
      }

      return dirtyData;
    },
    {},
  );
};

// Flatten nested object to single level object
// Example: { a: { b: { c: 1 } } } => { 'a.b.c': 1 }
// With array: { a: { b: { c: [1, 2, 3] } } } => { 'a.b.c.0': 1, 'a.b.c.1': 2, 'a.b.c.2': 3 }
// With stopPaths 'd.e': { a: { b: { c: 1 } }, d: { e: { f: 2 } } } => { 'a.b.c': 1, 'd.e': { f: 2 } }
export const flattenObject = (object: any, stopPaths?: string[]): Record<string, any> => {
  const stopPathsSet = new Set(stopPaths);

  const flatten = (input: any, parentPath?: string): Record<string, any> => {
    return reduce(
      input,
      (result, value, key) => {
        const path: string = parentPath === undefined ? key : `${parentPath}.${key}`;

        if (stopPathsSet.has(path)) {
          return { ...result, [path]: value };
        }

        if (value !== undefined && value !== null && typeof value === 'object') {
          return { ...result, ...flatten(value, path) };
        }

        return { ...result, [path]: value };
      },
      {} as Record<string, any>,
    );
  };

  return flatten(object, undefined);
};

export const updateLaunchDarklyContext = (ldClient: LDClient | undefined, contextUpdate: any): void => {
  if (contextUpdate !== undefined && ldClient !== undefined) {
    const launchdarklyContext = localStorage.getItem('launchdarkly.context');
    const context = JSON.parse(launchdarklyContext ?? '{}');
    const contextWithSiteId = { ...context, ...contextUpdate };

    void ldClient.identify(contextWithSiteId, undefined, () => {
      window.logger.info(`Launchdarkly client context: ${JSON.stringify(contextWithSiteId)}`);
      localStorage.setItem('launchdarkly.context', JSON.stringify(contextWithSiteId));
    });
  }
};
