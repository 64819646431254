import { type SerializedError } from '@reduxjs/toolkit';
import { createContext } from 'react';

export interface AppMetadata {
  username?: string;
  organization?: string;
  allowedStoreIds?: string;
}

export const ApplicationContext = createContext<AppMetadata>({
  username: undefined,
  organization: undefined,
  allowedStoreIds: undefined,
});

export enum REQUEST_STATUS {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export interface State<T> {
  data: T;
  requestStatus: REQUEST_STATUS;
  error: SerializedError | null;
}

export enum FeatureFlags {
  // Permanent feature flags go here, along with explanation of their purpose

  eyecueDashboardUseVersion = 'eyecueDashboardUseVersion',
  eyecueAdminUseAddNewDevice = 'eyecueAdminUseAddNewDevice', // Adding new device isn't available to customers yet, it's only available to Fingermark employees

  // Future feature flags go here, along with explanation of their purpose and when they can be cleaned up

  // This flag was added 2024-07-17 to support “per site” FeatureFlagging of Eyecue Monitor.
  // This approach was taken because LaunchDarkly does not support multiple site-ids in one context.
  // This feature flag will be removed when eyecue-monitor is stable and released
  eyecueAdminUseMonitorPerSite = 'eyecueAdminUseMonitorPerSite',

  // This feature flag was added on 2024-04-22 to enable quicksight embedding/reports in eyecue-admin
  // This feature flag can be removed when quicksight embedding is stable, and the functionality is gated behind role-based permissions
  eyecueAdminUseReports = 'eyecueAdminUseReports',

  // This feature flag was added on 2024-05-02 to enable target settings in eyecue-admin
  // This feature flag can be removed when target settings are stable and released
  eyecueAdminUseSettingsTarget = 'eyecueAdminUseSettingsTarget',

  // This feature flag was added on 2024-06-10 to enable sync historical data in eyecue-admin
  // This feature flag can be removed when sync historical data is stable and released
  eyecueAdminUseSyncHistoricalData = 'eyecueAdminUseSyncHistoricalData',

  // This feature flag was added on 2024-07-08 to enable dashboard library tab in eyecue-admin (under dashboard menu)
  // This feature flag can be removed when "dashboard library" feature is ready for customers
  eyecueAdminUseDashboardLibrary = 'eyecueAdminUseDashboardLibrary',

  // This feature flag was added on 2024-07-24 to enable dashboard trigger builder in eyecue-admin (under dashboard menu)
  // This feature flag can be removed when "trigger builder" feature is ready for customers
  eyecueAdminUseTriggerBuilder = 'eyecueAdminUseTriggerBuilder',

  // This feature flag was added on 2024-07-30 to rollout Attribute based access control (ABAC)
  // as above this flag can be removed when this feature is stable, check the merge commit branch jira ticket
  eyecuePortalUseAbac = 'eyecuePortalAbac',

  // This feature flag was added on 2024-08-20 to map temporary ids in trigger builder
  // as above this flag can be removed when this feature is stable, check the merge commit branch jira ticket
  eyecueAdminTriggerBuilderTemporaryIdMapping = 'eyecueAdminTriggerBuilderTemporaryIdMapping',

  // This feature flag was added on 2024-09-12 to enable help menu in eyecue-admin
  // This feature flag can be removed when help menu is stable and released
  eyecueAdminUseHelp = 'eyecueAdminUseHelp',
}
