import MircoApp from '../MicroApp';
import { FeatureFlags } from '@/types';
import { ContainedButton } from '../components/form/Buttons';
import Feature from '@/modules/components/feature';

const Monitor = (): JSX.Element => {
  const MonitorPicture = `${process.env.REACT_APP_EYECUE_ADMIN}/MonitorPicture.png`;

  return (
    <>
      <Feature flag={FeatureFlags.eyecueAdminUseMonitorPerSite}>
        <Feature.On>
          <MircoApp index={0} host={process.env.REACT_APP_EYECUE_MONITOR !== undefined ? `${process.env.REACT_APP_EYECUE_MONITOR}` : ''} name='Monitor' />
        </Feature.On>
        <Feature.Off>
          <div className='flex flex-col justify-center items-center font-family-Roboto pt-40'>
            <p className='mb-4 text-lg pb-5'>Sorry, Monitor hasn’t been activated for your store yet.</p>
            <img src={MonitorPicture} style={{ width: '787px', height: '471.84px' }} />
            <p className='mb-4 text-base pt-20'>To find out more about Monitor, or book a demo visit fingermark.ai/eyecue/</p>
            <ContainedButton
              className='min-w-fit'
              onClick={() => {
                window.open('https://fingermark.ai/eyecue/', '_blank');
              }}
            >
              Book a demo
            </ContainedButton>
          </div>
        </Feature.Off>
      </Feature>
    </>
  );
};

export default Monitor;
