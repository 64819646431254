import { createAsyncThunk } from '@reduxjs/toolkit';
import createHttp from '@/services/http';
import get from 'lodash/get';

export interface StoreData {
  clientId: string;
  storeId: string;
  details: {
    name: string;
    timezone: string;
  };
  targets: Array<{
    type: string;
    granularity: string;
    fixed?: number;
    adjustedInterval?: {
      sunday?: Record<string, number>;
      monday?: Record<string, number>;
      tuesday?: Record<string, number>;
      wednesday?: Record<string, number>;
      thursday?: Record<string, number>;
      friday?: Record<string, number>;
      saturday?: Record<string, number>;
    };
    lastUpdated?: string;
  }>;
}

export interface Ticket {
  name: string;
  email: string;
  phone: string;
  position: string;
  issue: string;
  storeId: string;
  storeName: string;
  category: string;
  serialNumber?: string;
  deviceName?: string;
}

const createHelpdeskTicketQuery = `
  mutation createHelpdeskTicket($name: String!, $email: String!, $phone: String, $position: String, $issue: String!, $siteId: String!, $siteName: String!, $category: String!, $serialNumber: String, $deviceName: String) {
    createHelpdeskTicket(name: $name, email: $email, phone: $phone, position: $position, issue: $issue, siteId: $siteId, siteName: $siteName, category: $category, serialNumber: $serialNumber, deviceName: $deviceName)
  }`;

export const createHelpdeskTicket = createAsyncThunk<any, Ticket>('_stores/createHelpdeskTicket', async (ticket): Promise<any> => {
  const { name, email, phone, position, issue, storeId, storeName, category, serialNumber, deviceName } = ticket;

  const response = await createHttp().post(process.env.REACT_APP_MIMIR_API ?? '', {
    query: createHelpdeskTicketQuery,
    variables: {
      name,
      email,
      phone,
      position,
      issue,
      siteId: storeId,
      siteName: storeName,
      category,
      serialNumber,
      deviceName,
    },
  });

  return response.data;
});

const updateTargetQuery = `
  mutation updateStoreV2($siteId: String!, $clientId: String!, $payload: JSON){
    updateStoreV2(siteId: $siteId, clientId: $clientId, payload: $payload) {
      storeId: siteId,
      targets {
        type,
        granularity,
        fixed,
        interval,
        adjustedInterval,
        lastUpdated
      }
    }
  }`;

export const updateTarget = createAsyncThunk<StoreData | null, { storeId: string; clientId: string; payload: Record<string, any> }>(
  '_stores/updateTarget',
  async ({ storeId, clientId, payload }): Promise<StoreData | null> => {
    const response = await createHttp().post(process.env.REACT_APP_MIMIR_API ?? '', {
      query: updateTargetQuery,
      variables: {
        siteId: storeId,
        clientId,
        payload,
      },
    });

    const storeTargets = get(response, 'data.data.updateStoreV2', null);

    if (storeTargets === null) {
      window.logger.error(`Failed to update targets for store ${storeId}`);
    }

    return storeTargets;
  },
);

// Revert to baseline should only affect to the reverted day
// Separate from "updateTarget" to not override the whole target object in target form
// The fields for reverted day are manually set in the form component
export const revertToBaseline = createAsyncThunk<Record<string, number>, { storeId: string; clientId: string; dayPath: string }>(
  '_stores/revertToBaseline',
  async ({ storeId, clientId, dayPath }): Promise<Record<string, number>> => {
    const response = await createHttp().post(process.env.REACT_APP_MIMIR_API ?? '', {
      query: updateTargetQuery,
      variables: {
        siteId: storeId,
        clientId,
        payload: { [dayPath]: null },
      },
    });

    const storeTargets = get(response, 'data.data.updateStoreV2', null);

    if (storeTargets === null) {
      window.logger.error(`Failed to update targets for store ${storeId}`);
    }

    return get(storeTargets, dayPath) as Record<string, number>;
  },
);

const getStoreTargetQuery = `
  query getStoresV2($siteIds: [String], $clientId: String) {
    getStoresV2(siteIds: $siteIds, clientId: $clientId) {
      storeId: siteId,
      details {
        name,
        timezone
      },
      targets {
        type,
        granularity,
        fixed,
        interval,
        adjustedInterval,
        lastUpdated
      }
    }
  }`;

export const getStoreData = createAsyncThunk<StoreData | undefined, { storeId: string; clientId: string }>(
  '_stores/getStoreData',
  async ({ storeId, clientId }): Promise<StoreData | undefined> => {
    const response = await createHttp().post(process.env.REACT_APP_MIMIR_API ?? '', {
      query: getStoreTargetQuery,
      variables: {
        siteIds: [storeId],
        clientId,
      },
    });

    const data = get(response, 'data.data.getStoresV2', []);
    const storeData = data.find((store: StoreData) => store.storeId === storeId);

    if (storeData === undefined || storeData === null) {
      window.logger.error(`No store found for storeId ${storeId} and clientId ${clientId}`);
      return undefined;
    }

    if (storeData.targets === undefined || storeData.targets === null || storeData.targets.length === 0) {
      window.logger.warn(`No targets found for store ${storeId}`);
    }

    return storeData;
  },
);
