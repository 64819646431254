import React, { useMemo } from 'react';
import TextInput from '@/modules/components/form/TextInput';
import { RemoveIcon } from '@/common/assets/icons';
import { type UseFieldArrayRemove, useFormContext } from 'react-hook-form';
import Dropdown from '@/modules/components/form/Dropdown';
import { statusOptions } from '../../common';
import { FeatureFlags } from '@/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isArray } from 'lodash';
import { type TriggerOption } from '@/stores/triggers/types';
import { insertEmptyOptionToDropDown } from '../helpers';

interface TableStatusProps {
  index: number;
  removeCondition: UseFieldArrayRemove;
  tableIds: TriggerOption[];
}

const TableStatus: React.FC<TableStatusProps> = ({ index, removeCondition }) => {
  const { [FeatureFlags.eyecueAdminTriggerBuilderTemporaryIdMapping]: eyecueAdminTriggerBuilderTemporaryIdMapping } = useFlags();
  const { tableIds } = useMemo(() => {
    const list = eyecueAdminTriggerBuilderTemporaryIdMapping?.tableIds;
    return {
      tableIds: list != null && isArray(list) ? list : [],
    };
  }, [eyecueAdminTriggerBuilderTemporaryIdMapping]);

  const {
    register,
    formState: { errors },
    setValue,
  }: any = useFormContext();

  const indexError = errors?.conditions?.[index];

  const dropDownError = useMemo(() => {
    if (indexError?.tableId != null) {
      return indexError?.tableId?.message;
    }
    if (indexError?.tableName != null) {
      return indexError?.tableName?.message;
    }
  }, [indexError]);

  return (
    <div key={index} className='flex space-x-4'>
      <div className='flex flex-row w-12 py-4 justify-center'>
        <button
          type='button'
          onClick={() => {
            removeCondition(index);
          }}
          className='mt-10 text-emphasis-primary flex flex-row'
        >
          <RemoveIcon />
        </button>
      </div>
      <div className={`flex flex-row w-full ${index !== 0 ? 'border-t border-emphasis-base' : ''}`}>
        <div className='flex flex-col py-10 w-40'>
          <div className='text-md text-emphasis-base mt-4'>Table Status</div>
        </div>
        <div className='flex flex-row space-x-2 py-5'>
          <input type='hidden' {...register(`conditions.${index}.id` as const)} />
          <input type='hidden' {...register(`conditions.${index}.metric` as const)} />
          <input
            type='hidden'
            {...register(`conditions.${index}.tableName` as const, {
              required: 'Table name is required',
              validate: (value: string) => value.length > 0 || 'Table name is required',
            })}
          />
          <div className='flex flex-col w-48'>
            <Dropdown
              options={insertEmptyOptionToDropDown(tableIds)}
              inline={false}
              errorUnderline={true}
              label={'Table'}
              className='flex-1 w-full'
              {...register(`conditions.${index}.tableId` as const, {
                required: 'Table ID is required',
                validate: (value: string) => value.length > 0 || 'Table ID is required',
                onChange: (e: any) => {
                  setValue(`conditions.${index}.tableName`, tableIds.find((c) => c.value === e.target.value)?.label, { shouldDirty: true });
                },
              })}
              errorMessage={dropDownError}
            />
          </div>
          <div className='flex flex-col w-48'>
            <Dropdown
              options={statusOptions}
              inline={false}
              label='Status'
              className='w-full flex-1'
              {...register(`conditions.${index}.status` as const)}
              errorMessage={indexError?.status}
            />
          </div>
          <TextInput
            className='w-full flex-1'
            inline={false}
            type='number'
            errorUnderline={true}
            label={'Duration'}
            {...register(`conditions.${index}.duration` as const, {
              required: 'Duration is required',
              validate: (value: number) => (value > 0 && value < 3600) || 'Should be between 1 and 3600',
              setValueAs: (value: string) => Number(value),
            })}
            errorMessage={indexError?.duration?.message}
          />
        </div>
      </div>
    </div>
  );
};

export default TableStatus;
