import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCreative } from 'swiper/modules';
import Dashboard from '@/modules/dashboards/dashboard';
import { LeftArrowWithCircleIcon } from '@/common/assets/icons';
import { EyecueLogo } from '@/layouts/components/logos';
import { createElement, lazy, Suspense, useRef } from 'react';
import type { Swiper as SwiperClass } from 'swiper/types';
import Spinner from '@/modules/components/spinner';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-creative';

const KioskInteractiveLayout = (): JSX.Element => {
  const swiperRef = useRef<SwiperClass>(null);

  return (
    <main className='h-screen'>
      <Swiper
        className='h-full'
        grabCursor={true}
        effect={'creative'}
        creativeEffect={{
          prev: {
            shadow: true,
            translate: [0, 0, -800],
          },
          next: {
            translate: ['100%', 0, 0],
          },
        }}
        modules={[EffectCreative]}
      >
        <SwiperSlide>
          <Dashboard />
          <div className='absolute bottom-0 right-0 pb-2 pr-2'>
            <span className='text-emphasis-loud text-sm font-normal'>Swipe right for help</span>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='fixed inset-y-0 flex min-w-20 5xl:w-72 flex-col z-50'>
            <div className='flex grow flex-col gap-y-5 overflow-y-auto border-l bg-menu-background'>
              <nav className='flex flex-1 flex-col'>
                <ul role='list' className='flex flex-1 flex-col gap-y-6'>
                  <li className='h-16 flex items-center pl-[27px]'>
                    <EyecueLogo logoClassName='fill-white' useBrand={false} />
                  </li>
                  <li
                    className='ml-auto mr-auto'
                    onClick={() => {
                      if (swiperRef.current !== null) {
                        swiperRef.current.slidePrev();
                      }
                    }}
                  >
                    <LeftArrowWithCircleIcon className='fill-emphasis-primary' />
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div className='pl-20 5xl:pl-72 h-full bg-dashboard-background'>
            <Suspense fallback={<Spinner />}>
              {createElement(
                lazy(async () => {
                  return await import('@/modules/help').catch((error) => {
                    window.logger.error(error.message);
                    throw error;
                  });
                }),
              )}
            </Suspense>
          </div>
        </SwiperSlide>
      </Swiper>
    </main>
  );
};

export default KioskInteractiveLayout;
