import React from 'react';
import { useAppSelector } from '@/common';
import EmptyList from './EmptyList';
import TriggerTable from './TriggerTable';
import { selectIsTriggersLoading, selectSiteTriggersMapped } from '@/stores/triggers';

const Triggers = (): JSX.Element => {
  const isLoading = useAppSelector(selectIsTriggersLoading);
  const siteTriggersMapped = useAppSelector(selectSiteTriggersMapped);

  if (isLoading) {
    return <div />;
  }
  return siteTriggersMapped?.triggers?.length === 0 ? <EmptyList /> : <TriggerTable triggers={siteTriggersMapped?.triggers} />;
};

export default Triggers;
