import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { type DestinationDashboard } from '../../../stores/triggers/types';
import { getNewDestinationDashboard } from '../edit/helpers';
import Dropdown from '@/modules/components/form/Dropdown';
import { AddIcon, RemoveIcon } from '@/common/assets/icons';
import { selectDashboards } from '@/stores/dashboards';
import { useAppSelector } from '@/common';
import { useParams } from 'react-router-dom';
import { ApplicationContext } from '@/types';
import { isEmpty } from 'lodash';

interface DestinationDashboardProps {
  index: number;
}

const DashboardList: React.FC<DestinationDashboardProps> = ({ index }) => {
  const name = `actions.${index}.destinations` as any;
  const { storeId } = useParams();
  const applicationContext = useContext(ApplicationContext);
  const [dashboardInput, setDashboardInput] = useState<string | null>(null);
  const {
    register,
    formState: { errors },
    setError,
    setValue,
    getValues,
  }: any = useFormContext();
  const [dashboardList, setDashboardList] = useState<DestinationDashboard[]>(getValues(name));

  const { loStoreId, loOrganisationId } = useMemo(() => {
    return {
      loStoreId: storeId,
      loOrganisationId: applicationContext.organization,
    };
  }, [applicationContext, storeId]);

  useEffect(() => {
    setValue(name, dashboardList, { shouldDirty: true });
  }, [dashboardList]);

  useEffect(() => {
    if (dashboardInput != null) {
      handleAddDashboard();
    }
  }, [dashboardInput]);

  const handleAddDashboard = (): void => {
    if (dashboardInput != null) {
      const newDashboard = getNewDestinationDashboard(dashboardInput, loStoreId as string, loOrganisationId as string);
      const existingDashboard = dashboardList.find((dashboard) => dashboard.dashboardId === newDashboard.dashboardId);

      if (existingDashboard != null) {
        setDashboardInput(null);
        setError(name, {
          type: 'manual',
          message: 'Dashboard already exists in the list',
        });
      } else {
        setDashboardList([...dashboardList, newDashboard]);
        setDashboardInput(null);
        setError(name, { type: 'manual', message: '' });
      }
    } else {
      setDashboardInput(null);
      setError(name, {
        type: 'manual',
        message: 'Dashboard destination has to be selected first',
      });
    }
  };

  const handleDeleteDashboard = (dashboardToRemove: string): void => {
    setDashboardList(dashboardList.filter((dashboard) => dashboard.id !== dashboardToRemove));
  };

  const errorDestination: any = errors?.actions?.[index]?.destinations?.message;

  const dashboards = useAppSelector(selectDashboards);
  const dashboardOptions = [
    {
      value: '',
      label: 'Select dashboard',
    },
    ...dashboards.map((dashboard) => ({
      value: dashboard.id,
      label: dashboard.name,
    })),
  ];

  const findDashboardById = (id: string): string => {
    const dash = dashboardOptions.find((dashboard) => dashboard.value === id);
    return dash?.label != null ? dash?.label : '';
  };

  return (
    <div className='mb-4 flex space-x-8'>
      <div className='flex w-72'>
        <div className='flex space-x-2'>
          <div className='flex flex-1 relative w-full'>
            <Dropdown
              className='relative flex flex-1 w-72'
              options={dashboardOptions}
              errorUnderline={true}
              inline={false}
              label='Dashboard'
              value={dashboardInput != null ? dashboardInput : ''}
              onChange={(e: any) => {
                e.preventDefault();
                if (!isEmpty(e.target.value)) {
                  setDashboardInput(e.target.value);
                }
              }}
              onBlur={(e: any) => {
                if (!isEmpty(e.target.value)) {
                  setDashboardInput(e.target.value);
                }
              }}
              errorMessage={errorDestination}
            />
          </div>
          <button
            type='button'
            className='mt-8'
            onClick={(e) => {
              e.preventDefault();
              handleAddDashboard();
            }}
          >
            <AddIcon />
          </button>
          <input
            type='hidden'
            {...register(name, {
              validate: (value: any) => value.length > 0 || 'Please select at least one dashboard destination',
            })}
          />
        </div>
      </div>
      <div className='flex pt-7'>
        <div className='absolute w-auto h-auto flex flex-wrap'>
          {dashboardList.map((dashboard, index2: React.Key | null | undefined) => (
            <div
              key={index2}
              className='inline-flex h-8 mt-2 mr-2 ml-2 items-center border border-emphasis-base text-emphasis-base rounded-full px-3 text-sm shadow-none'
            >
              <span>{findDashboardById(dashboard?.dashboardId != null ? dashboard?.dashboardId : '')}</span>
              <button
                className='ml-2'
                onClick={(e) => {
                  e.preventDefault();
                  handleDeleteDashboard(dashboard.id);
                }}
              >
                <RemoveIcon />
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DashboardList;
