// https://tailwindui.com/components/application-ui/application-shells/sidebar

import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { EyecueLogo } from '@/layouts/components/logos';

export interface NavigationModel {
  label: string;
  name: string;
  href: string;
  disabled?: boolean;
  icon?: any;
}

export const SidebarMenuItem = (_: NavigationModel): JSX.Element => <></>;

export const Sidebar = ({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element => {
  const getMenuClassName = (isActive: boolean, disabled: boolean): string =>
    classNames(
      disabled
        ? 'text-emphasis-menu-disabled'
        : isActive
        ? 'bg-menu-background-selected text-emphasis-menu-selected fill-emphasis-menu-selected'
        : 'text-emphasis-menu-base',
      'flex items-center gap-x-3 leading-6 font-normal text-base py-5 pl-[27px]',
    );

  const childrenArray = Array.isArray(children) ? children : [children];
  const menuItems = childrenArray.filter((item) => item.props.name !== 'logout');
  const logoutItem = childrenArray.find((item) => item.props.name === 'logout');

  return (
    <div className='fixed inset-y-0 flex min-w-20 5xl:w-72 flex-col z-50'>
      {/* Sidebar component */}
      <div className='flex grow flex-col gap-y-5 overflow-y-auto border-l bg-menu-background'>
        <nav className='flex flex-1 flex-col'>
          <ul role='list' className='flex flex-1 flex-col gap-y-7'>
            <li>
              <ul role='list' className='divide-y divide-slate-50 divide-opacity-20'>
                <li className='h-16 flex items-center pl-[27px]'>
                  <EyecueLogo logoClassName='fill-white' brandClassName='fill-white' />
                </li>
                {menuItems.map((item) => {
                  const { label, name, href, disabled = false } = item.props;

                  return (
                    <li key={name}>
                      <NavLink
                        style={{ pointerEvents: disabled ? 'none' : 'auto' }}
                        className={({ isActive }): string => getMenuClassName(isActive, disabled)}
                        to={href}
                      >
                        {({ isActive }) => (
                          <>
                            <item.props.icon className={disabled ? 'fill-emphasis-menu-disabled' : isActive ? 'fill-emphasis-menu-selected' : 'fill-white'} />
                            <span className='min-[0px]:max-5xl:hidden 5xl:flex'>{label}</span>
                          </>
                        )}
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
            </li>
            <li className='mt-auto'>
              <ul role='list' className='flex flex-1 flex-col divide-y divide-slate-50 divide-opacity-20'>
                {logoutItem && (
                  <li>
                    <NavLink
                      style={{ pointerEvents: logoutItem.props.disabled ? 'none' : 'auto' }}
                      className={classNames({
                        [getMenuClassName(false, logoutItem.props.disabled)]: true,
                      })}
                      to={logoutItem.props.href}
                    >
                      {({ isActive }) => (
                        <>
                          <logoutItem.props.icon
                            className={logoutItem.props.disabled ? 'fill-emphasis-menu-disabled' : isActive ? 'fill-emphasis-menu-selected' : 'fill-white'}
                          />
                          <span className='min-[0px]:max-5xl:hidden 5xl:flex'>{logoutItem.props.label}</span>
                        </>
                      )}
                    </NavLink>
                  </li>
                )}
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};
