import { createDevice, pushDevice, type Device } from '@/stores/devices';
import { type SubmitHandler, useForm, Controller } from 'react-hook-form';
import TextInput from '../../components/form/TextInput';
import Switch from '../../components/form/Switch';
import { useEffect, useMemo } from 'react';
import { toThemeName } from '../utils';
import { useAppDispatch, useAppSelector } from '@/common';
import { selectDashboards } from '@/stores/dashboards';
import SelectInput from '@/modules/components/form/SelectInput';
import { ContainedButton, TextButton } from '@/modules/components/form/Buttons';
import DialogWrapper, { CloseButton, type DialogProps, ModalContent, ModalHeader, ModalTitle } from '@/modules/components/dialogWrapper';
import { useJitsu } from '@jitsu/jitsu-react';

interface NewDeviceModel extends Device {
  lightTheme: boolean;
}

interface NewDeviceFormProps extends DialogProps {
  storeId: string;
}

const NewDeviceForm = (props: NewDeviceFormProps): JSX.Element => {
  const { storeId, open, setOpen } = props;
  const dispatch = useAppDispatch();
  const dashboards = useAppSelector(selectDashboards);
  const dashboardOptions = useMemo(() => dashboards.map((d) => ({ label: d.name, value: d.id })), [dashboards]);
  const { analytics } = useJitsu();
  const {
    control,
    formState: { errors, isSubmitting, isDirty },
    register,
    handleSubmit,
    setError,
    reset,
  } = useForm<NewDeviceModel>({
    defaultValues: {
      serialNumber: '',
      name: '',
      lightTheme: false,
      dashboard: undefined,
    },
  });

  useEffect(reset, [open]);

  const onSubmit: SubmitHandler<NewDeviceModel> = async (data: NewDeviceModel) => {
    const device: Device = { ...data, storeId, theme: toThemeName(data.lightTheme) };

    try {
      const result = await dispatch(createDevice(device)).unwrap();
      dispatch(pushDevice(result));
      setOpen(false);
      analytics.track('screen_screenAdded', { device: { serialNumber: device.serialNumber } });
    } catch (errors: any) {
      try {
        const error = JSON.parse(errors[0]);

        for (const key in error) {
          setError(key as 'serialNumber' | 'name', { type: 'manual', message: error[key] });
        }
      } catch {}
    }
  };

  return (
    <DialogWrapper open={open} setOpen={setOpen}>
      <ModalHeader>
        <ModalTitle>Add new screen</ModalTitle>
        <div className='flex flex-1 justify-end'>
          <CloseButton
            onClick={(e: any) => {
              e.preventDefault();
              setOpen(false);
            }}
          />
        </div>
      </ModalHeader>
      <ModalContent>
        <form className='space-y-6 min-w-96' onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            className='w-full'
            label='Serial number'
            errorMessage={errors.serialNumber?.message}
            {...register('serialNumber', { required: 'Serial Number cannot be empty.' })}
          />
          <TextInput className='w-full' label='Name' errorMessage={errors.name?.message} {...register('name', { required: 'Name cannot be empty.' })} />
          <Controller name='lightTheme' control={control} render={({ field }) => <Switch labelLeft='Light mode' lableRight='Dark mode' {...field} />} />
          <SelectInput className='w-full' label='Dashboard' options={dashboardOptions} {...register('dashboard')} />

          {/* Form control buttons */}
          <div className='mt-6 flex items-center justify-end gap-x-6'>
            <TextButton
              disabled={isSubmitting || !isDirty}
              onClick={(e) => {
                e.preventDefault();
                setOpen(false);
              }}
            >
              Cancel
            </TextButton>
            <ContainedButton disabled={isSubmitting || !isDirty}>Save</ContainedButton>
          </div>
        </form>
      </ModalContent>
    </DialogWrapper>
  );
};

export default NewDeviceForm;
