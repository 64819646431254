import Header, { Title } from '../components/header';
import { useAppSelector, useCurrentStore } from '@/common';
import { Navigation, NavigationItem } from '../components/navigation';
import ContentPanel from '../components/contentPanel';
import { selectIsTriggersLoading } from '@/stores/triggers';
import { Outlet } from 'react-router-dom';

const Triggers = (): JSX.Element => {
  const currentStore = useCurrentStore();
  const isLoading = useAppSelector(selectIsTriggersLoading);

  return (
    <>
      <Header>
        <Title>{`${currentStore?.name ?? ''} triggers`}</Title>
        <Navigation>
          <NavigationItem label='All triggers' name='all-triggers' to='all' />
          <NavigationItem label='Create triggers' name='created-triggers' to='create' />
        </Navigation>
      </Header>
      <ContentPanel isLoading={isLoading}>
        <Outlet />
      </ContentPanel>
    </>
  );
};

export default Triggers;
