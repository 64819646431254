import createHttp from '@/services/http';
import { createAsyncThunk } from '@reduxjs/toolkit';
import get from 'lodash/get';
import { type FormValues } from './types';
import { type RootState } from '..';
import { getInitialMappedTrigger, mergeMappedTrigger, removeTriggerAndRelatedObjects } from './helpers';
import { isEmpty } from 'lodash';

interface TriggerData {
  siteTrigger: FormValues;
  siteId: string;
  organisationId: string;
}

interface DeleteTriggerData {
  triggerId: string;
}

const updateTriggerQuery = `
  mutation upsertTrigger($siteTrigger: SiteTriggersInput!){
    upsertTrigger(siteTrigger: $siteTrigger) {
      status_code
      error
    }
  }`;

export const updateTrigger = createAsyncThunk<TriggerData | null, { siteTrigger: FormValues; siteId: string }>(
  'triggers/upsertTrigger',
  async ({ siteTrigger, siteId }, { getState }): Promise<any> => {
    const rootState = getState() as RootState;
    const triggersState = rootState.triggers;
    const currentDataToMerge = isEmpty(triggersState.data)
      ? getInitialMappedTrigger(siteId, siteTrigger.organisationId, siteTrigger.organisationName)
      : triggersState.data;

    const requestData = mergeMappedTrigger(currentDataToMerge, siteTrigger);

    const response = await createHttp().post(process.env.REACT_APP_MIMIR_API ?? '', {
      query: updateTriggerQuery,
      variables: {
        siteTrigger: requestData,
      },
    });

    const trigger = get(response, 'data.data.upsertTrigger', null);

    if (trigger === null) {
      window.logger.error(`Failed to update site trigger for site ${siteTrigger.id}`);
    }

    return trigger;
  },
);

export const deleteTrigger = createAsyncThunk<DeleteTriggerData, { triggerId: string }>(
  'triggers/deleteTrigger',
  async ({ triggerId }, { getState }): Promise<any> => {
    const rootState = getState() as RootState;
    const triggersState = rootState.triggers;
    const requestData = removeTriggerAndRelatedObjects(triggersState.data, triggerId);

    const response = await createHttp().post(process.env.REACT_APP_MIMIR_API ?? '', {
      query: updateTriggerQuery,
      variables: {
        siteTrigger: requestData,
      },
    });

    const trigger = get(response, 'data.data.upsertTrigger', null);

    if (trigger === null) {
      window.logger.error(`Failed to update  site trigger for site ${requestData.siteId}`);
    }

    return trigger;
  },
);
