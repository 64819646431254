import { useForm } from 'react-hook-form';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@/common';
import { ContainedButton } from '@/modules/components/form/Buttons';
import TextInput from '@/modules/components/form/TextInput';
import { selectDashboardTemplate } from '@/stores/dashboardTemplates';
import { createDashboard as createDashboardAction, type NewDashboard } from '@/stores/dashboards';
import { useNavigate } from 'react-router-dom';
import { useJitsu } from '@jitsu/jitsu-react';

interface NewDashboardFormProps {
  storeId: string;
  templateId: string;
}

const NewDashboardForm = (props: NewDashboardFormProps): JSX.Element => {
  const { storeId, templateId } = props;
  const selectedTemplate = useAppSelector(selectDashboardTemplate(templateId ?? ''));
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { analytics } = useJitsu();
  const {
    formState: { errors, isSubmitting },
    register,
    handleSubmit,
    reset,
    setError,
  } = useForm<NewDashboard>({
    defaultValues: {
      storeId,
      name: selectedTemplate?.name ?? '',
      template: selectedTemplate?.template ?? {},
    },
    reValidateMode: 'onSubmit',
  });

  useEffect(reset, [open]);

  const onSubmit = async (data: NewDashboard): Promise<void> => {
    const slots = JSON.parse(localStorage.getItem(`template|${storeId}|${templateId}`) ?? '{}');

    try {
      await dispatch(
        createDashboardAction({
          ...data,
          template: {
            ...data.template,
            slots: {
              ...data.template.slots,
              ...slots,
            },
          },
        }),
      ).unwrap();

      // back to the dashboard list
      navigate(-1);
      analytics.track('dashboard_dashboardAdded', { dashboard: { name: data.name } });
    } catch (errors: any) {
      const error = JSON.parse(errors[0]);

      for (const key in error) {
        setError(key as 'name', { type: 'manual', message: error[key] });
      }
    }
  };

  return (
    <form className='space-y-6' onSubmit={handleSubmit(onSubmit)}>
      {/* Form control buttons */}
      <div className='flex items-center justify-end'>
        <ContainedButton disabled={isSubmitting}>Save</ContainedButton>
      </div>

      <TextInput
        className='w-full'
        label={<span className='text-emphasis-primary font-semibold'>Dashboard name</span>}
        errorMessage={errors.name?.message}
        {...register('name', { required: 'Dashboard name cannot be empty.' })}
      />
    </form>
  );
};

export default NewDashboardForm;
