import { type Device, updateDevice } from '@/stores/devices/actions';
import Switch from '../../components/form/Switch';
import { useAppDispatch, useAppSelector } from '@/common';
import { selectDashboards } from '@/stores/dashboards';
import { useMemo, useState } from 'react';
import SelectInput from '../../components/form/SelectInput';
import { toThemeName, toThemeToggle } from '../utils';
import { mergeDevice } from '@/stores/devices';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useJitsu } from '@jitsu/jitsu-react';

interface DeviceItemProps {
  device: Device;
}

const DeviceItem = (props: DeviceItemProps): JSX.Element => {
  const { eyecueAdminUseChangeDeviceDashboard } = useFlags();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { device } = props;
  const dashboards = useAppSelector(selectDashboards);
  const dashboardOptions = useMemo(() => dashboards.map((d) => ({ label: d.name, value: d.id })), [dashboards]);
  const { analytics } = useJitsu();

  const onChanged = async (params: { name?: string; theme?: string; dashboard?: string }): Promise<void> => {
    try {
      setIsLoading(true);
      const result = await dispatch(updateDevice({ ...device, params })).unwrap();
      dispatch(mergeDevice(result));
    } catch (error: any) {
      window.logger.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='flex min-w-0 gap-x-4'>
      <div className='min-w-0 flex-auto grid grid-cols-2'>
        {/* Device information */}
        <div>
          <p className='text-lg font-medium leading-6 text-emphasis-primary'>{device.name}</p>
          <p className='mt-1 truncate text-xs font-normal leading-5 text-[#666666]'>{device.serialNumber}</p>
        </div>

        {/* Device controls */}
        <div className='space-y-6'>
          <Switch
            labelLeft='Light mode'
            lableRight='Dark mode'
            name='theme'
            value={toThemeToggle(device.theme)}
            onChange={(value) => {
              onChanged({ theme: toThemeName(value) });
              analytics.track('screen_themeChanged', { device: { serialNumber: device.serialNumber } });
            }}
            disabled={isLoading}
          />
          <SelectInput
            label='Current dashboard'
            name='dashboard'
            options={dashboardOptions}
            value={device.dashboard}
            inline
            onChange={(e) => {
              onChanged({ dashboard: e.target.value });
              analytics.track('screen_dashboardChanged', { device: { serialNumber: device.serialNumber } });
            }}
            disabled={eyecueAdminUseChangeDeviceDashboard !== true || isLoading}
            className='ml-4'
          />
        </div>
      </div>
    </div>
  );
};

export default DeviceItem;
