import { useAppSelector } from '@/common';
import { usePermissions } from '@/modules/hooks';
import { selectDashboards } from '@/stores/dashboards';
import { useNavigate } from 'react-router-dom';

const ConfiguredDashboards = (): JSX.Element => {
  const dashboards = useAppSelector(selectDashboards);
  const navigate = useNavigate();

  const { hasPermission } = usePermissions();

  return (
    <ul role='list' className='divide-y divide-divider-light'>
      {dashboards.map((dashboard) => (
        <li key={dashboard.id} className='relative flex items-center justify-between gap-x-6 px-[10px] py-6'>
          <div className='flex min-w-0 gap-x-4'>
            <div className='grid grid-cols-2 gap-x-48 items-center min-w-0 flex-auto text-base font-normal leading-6 text-emphasis-secondary'>
              <p className='leading-6 min-w-80 text-emphasis-primary font-medium'>{dashboard.name}</p>
              <span className='isolate inline-flex'>
                {hasPermission('dashboard', ['full-access']) && (
                  <button
                    type='button'
                    className='relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10'
                    onClick={() => {
                      navigate(`${dashboard.id}/preview?heightOffset=170px`);
                    }}
                  >
                    Edit
                  </button>
                )}
                <button
                  type='button'
                  className='relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10'
                  onClick={() => {
                    navigate(`${dashboard.id}/live`);
                  }}
                >
                  Live dashboard
                </button>
              </span>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default ConfiguredDashboards;
