export enum Comparator {
  LESS_THAN_OR_EQUAL = 'LESS_THAN_OR_EQUAL',
  GREATER_THAN_OR_EQUAL = 'GREATER_THAN_OR_EQUAL',
  EQUAL = 'EQUAL',
}

export enum MonitorEvents {
  TIME_IN_INGRESS = 'TIME_IN_INGRESS',
  TIME_IN_ORDER = 'TIME_IN_ORDER',
  TIME_IN_WINDOW = 'TIME_IN_WINDOW'
}

export enum ConditionTableStatusStatus {
  DIRTY = 'dirty',
  IN_USE = 'in_use',
  CLEAN = 'clean'
}

export enum ActionTypes {
  NOTIFICATION = 'NOTIFICATION',
  MONITOR = 'MONITOR',
  EMAIL = 'EMAIL'
}

export enum ConditionTypes {
  TIME_IN_ROI = 'TIME_IN_ROI',
  CHIP_BAY_PERCENTAGE = 'CHIP_BAY_PERCENTAGE',
  TABLE_STATUS = 'TABLE_STATUS'
}

export enum DestinationType {
  DASHBOARD = 'DASHBOARD',
  EMAIL = 'EMAIL'
}

export interface ConditionTableStatus {
  id: string;
  metric: ConditionTypes.TABLE_STATUS;
  status: ConditionTableStatusStatus;
  tableId?: string;
  tableName?: string;
  duration: number;
}

export interface ConditionTimeInRoi {
  id: string;
  metric: ConditionTypes.TIME_IN_ROI;
  threshold: number;
  targetType?: string;
  roiId: string;
  roiName?: string;
  comparator: Comparator;
}

export interface ConditionChipBayPercentage {
  id: string;
  metric: ConditionTypes.CHIP_BAY_PERCENTAGE;
  duration: number;
  targetType?: string;
  chipBayId: string;
  chipBayName?: string;
  threshold: number;
  comparator: Comparator;
}

export type Condition = ConditionTimeInRoi | ConditionChipBayPercentage | ConditionTableStatus;

export interface DestinationDashboard {
  id: string;
  type: DestinationType.DASHBOARD;
  siteId: string;
  organisationId: string;
  dashboardId?: string;
}

export interface DestinationEmail {
  id: string;
  type: DestinationType.EMAIL;
  address: string;
}

export type Destination = DestinationDashboard | DestinationEmail;

export interface Trigger {
  title: string;
  id: string;
  actions: string[];
  conditions: string[];
  enabled: boolean;
}

export interface ActionMonitor {
  id: string;
  type: ActionTypes.MONITOR;
  actionItem: string;
}

export interface ActionEmail {
  id: string;
  type: ActionTypes.EMAIL;
  actionItem: string;
  destinations: string[];
}

export interface ActionNotification {
  id: string;
  type: ActionTypes.NOTIFICATION;
  actionItem: string;
  destinations: string[];
}

export type Action = ActionMonitor | ActionEmail | ActionNotification;

export interface ActionItemMonitor {
  id: string;
  type: ActionTypes.MONITOR;
  eventType: string;
  cameraIds?: string[];
  preRoll?: number;
  postRoll?: number;
}

export interface ActionItemEmail {
  id: string;
  type: ActionTypes.EMAIL;
  subject: string;
  body: string;
}

export interface ActionItemNotification {
  id: string;
  type: ActionTypes.NOTIFICATION;
  title: string;
  description: string;
  ttl: number;
  level: string;
}

export type ActionItem = ActionItemMonitor | ActionItemEmail | ActionItemNotification;

export interface SiteTriggers {
  siteId: string;
  organisationId: string;
  organisationName: string;
  lastUpdatedTimestamp: string;
  version: string;
  conditions: Condition[];
  destinations: Destination[];
  triggers: Trigger[];
  actions: Action[];
  actionItems: ActionItem[];
}

export interface MappedAction {
  id: string;
  type: ActionTypes;
  actionItem: ActionItem;
  destinations?: Destination[];
}

export interface MappedTrigger {
  id: string;
  title: string;
  enabled: boolean;
  conditions: Condition[];
  actions: MappedAction[];
}

export interface MappedSiteTriggers {
  siteId: string;
  organisationId: string;
  organisationName: string;
  lastUpdatedTimestamp: string;
  triggers: MappedTrigger[];
}

export interface MappedSiteTrigger {
  siteId: string;
  organisationId: string;
  organisationName: string;
  lastUpdatedTimestamp: string;
  trigger?: MappedTrigger;
}

export interface FormValues {
  id: string;
  title: string;
  enabled: boolean;
  organisationId: string;
  organisationName: string;
  conditions: Condition[];
  actions: MappedAction[];
}

// TypeScript Input Types
export type ConditionInput = ConditionTimeInRoiInput | ConditionChipBayPercentageInput | ConditionTableStatusInput;
export type DestinationInput = DestinationDashboardInput | DestinationEmailInput;
export type ActionInput = ActionMonitorInput | ActionEmailInput | ActionNotificationInput;
export type ActionItemInput = ActionItemMonitorInput | ActionItemEmailInput | ActionItemNotificationInput;

// TypeScript type for ConditionTableStatusInput
export interface ConditionTableStatusInput {
  id: string;
  metric: ConditionTypes.TABLE_STATUS;
  status: ConditionTableStatusStatus;
  tableId: string;
  tableName?: string;
  duration: number;
}

// TypeScript type for ConditionTimeInRoiInput
export interface ConditionTimeInRoiInput {
  id: string;
  metric: ConditionTypes.TIME_IN_ROI;
  threshold: number;
  targetType?: string;
  roiId: string;
  roiName?: string;
}

// TypeScript type for ConditionChipBayPercentageInput
export interface ConditionChipBayPercentageInput {
  id: string;
  metric: ConditionTypes.CHIP_BAY_PERCENTAGE;
  duration: number;
  targetType?: string;
  chipBayId: string;
  chipBayName?: string;
  comparator: Comparator;
  threshold: number;
}

// TypeScript type for DestinationDashboardInput
export interface DestinationDashboardInput {
  id: string;
  type: DestinationType.DASHBOARD;
  siteId: string;
  organisationId: string;
  dashboardId?: string;
}

// TypeScript type for DestinationEmailInput
export interface DestinationEmailInput {
  id: string;
  type: DestinationType.EMAIL;
  address: string;
}

// TypeScript type for ActionMonitorInput
export interface ActionMonitorInput {
  id: string;
  type: ActionTypes.MONITOR;
  actionItem: string;
}

// TypeScript type for ActionEmailInput
export interface ActionEmailInput {
  id: string;
  type: ActionTypes.EMAIL;
  actionItem: string;
  destinations: string[];
}

// TypeScript type for ActionNotificationInput
export interface ActionNotificationInput {
  id: string;
  type: ActionTypes.NOTIFICATION;
  actionItem: string;
  destinations: string[];
}

// TypeScript type for ActionItemMonitorInput
export interface ActionItemMonitorInput {
  id: string;
  type: ActionTypes.MONITOR;
  eventType: string;
  cameraIds?: string[];
  preRoll?: number;
  postRoll?: number;
}

// TypeScript type for ActionItemEmailInput
export interface ActionItemEmailInput {
  id: string;
  type: ActionTypes.EMAIL;
  subject: string;
  body: string;
}

// TypeScript type for ActionItemNotificationInput
export interface ActionItemNotificationInput {
  id: string;
  type: ActionTypes.NOTIFICATION;
  title: string;
  description: string;
  ttl: number;
  level: string;
}

// TypeScript type for SiteTriggersInput
export interface SiteTriggersInput {
  siteId: string;
  organisationId: string;
  organisationName: string;
  lastUpdatedTimestamp: string;
  version: string;
  conditions: ConditionInput[];
  destinations: DestinationInput[];
  triggers: TriggerInput[];
  actions: ActionInput[];
  actionItems: ActionItemInput[];
}

// TypeScript type for TriggerInput
export interface TriggerInput {
  title: string;
  id: string;
  actions: string[];
  conditions: string[];
  enabled: boolean;
}

export interface TriggerOption {
  value: string;
  label: string;
}