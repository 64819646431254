import React, { useCallback } from 'react';
import { AddIcon } from '@/common/assets/icons';

interface Item {
  label: string;
  value: string;
}
interface DestinationEmailProps {
  type: 'condition' | 'destination';
  items: Item[];
  handleAddItem: (item: string) => void;
  numberOfItems: number;
}

const NewItemPicker: React.FC<DestinationEmailProps> = ({ items, handleAddItem, type, numberOfItems }) => {
  const renderItem = useCallback(
    (item: Item) => {
      return (
        <button
          key={item.value}
          className='h-8 inline-flex items-center text-white bg-button-primary text-emphasis-base rounded-full px-3 py-1 text-sm shadow-none'
          onClick={(e) => {
            e.preventDefault();
            handleAddItem(item.value);
          }}
        >
          <span>{item.label}</span>
          <AddIcon className='relative ml-2' fill='#fff' />
        </button>
      );
    },
    [handleAddItem],
  );

  return items.length === 0 ? null : (
    <div className='flex flex-row w-full py-2.5 space-x-2'>
      <div className='flex flex-row w-44'>
        <div className='py-4 text-emphasis-primary'>{`Add ${numberOfItems > 0 ? 'another ' : 'a'} ${type}`}</div>
      </div>
      <div className='flex flex-row space-x-2 py-3'>{items.map((item) => renderItem(item))}</div>
    </div>
  );
};

export default NewItemPicker;
