import { useNavigate } from 'react-router-dom';

const EmptyList = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <div className='flex flex-col items-center justify-center mt-[100px]'>
      <h1 className='text-2xl font-semibold text-emphasis-base mt-8'>Your store doesn’t have any triggers set yet</h1>
      <button
        onClick={() => {
          navigate(`../create`);
        }}
        className='mt-4 px-6 py-3 text-white font-semibold rounded-lg shadow-md bg-button-primary hover:bg-button-primary-hover'
      >
        Create your own
      </button>
    </div>
  );
};

export default EmptyList;
