import React from 'react';
import { useFormContext, type UseFieldArrayRemove } from 'react-hook-form';
import { RemoveIcon } from '@/common/assets/icons';
import TextInput from '@/modules/components/form/TextInput';
import { isArray, isEmpty } from 'lodash';

interface DestinationMonitorProps {
  index: number;
  removeAction: UseFieldArrayRemove;
}

const DestinationMonitor: React.FC<DestinationMonitorProps> = ({ index, removeAction }) => {
  const {
    register,
    formState: { errors },
  }: any = useFormContext();

  const indexError = errors?.actions?.[index];

  return (
    <div className='flex space-x-4'>
      <div className='flex flex-row w-12 py-2.5 justify-center'>
        <button
          type='button'
          onClick={() => {
            removeAction(index);
          }}
          className='mt-7 text-emphasis-primary flex flex-row'
        >
          <RemoveIcon />
        </button>
      </div>
      <div className={`flex flex-row w-full pb-4 ${index !== 0 && 'border-t border-emphasis-base'}`}>
        <div className='flex flex-col py-10 w-40'>
          <div className='text-md text-emphasis-base'>Monitor</div>
        </div>
        <div className='flex flex-col space-y-5 py-2.5'>
          <input type='hidden' {...register(`actions.${index}.id` as const)} />
          <input type='hidden' {...register(`actions.${index}.type` as const)} />
          <input type='hidden' {...register(`actions.${index}.actionItem.id` as const)} />
          <input type='hidden' {...register(`actions.${index}.actionItem.type` as const)} />
          <input type='hidden' {...register(`actions.${index}.actionItem.preRoll` as const)} />
          <input type='hidden' {...register(`actions.${index}.actionItem.postRoll` as const)} />
          <input
            type='hidden'
            {...register(`actions.${index}.actionItem.cameraIds` as const, {
              setValueAs: (value: string[]) => {
                const list = value !== null && isArray(value) && value.length > 0 && value.filter((item) => !isEmpty(item));
                return list !== null && isArray(list) && list.length > 0 ? list : null;
              },
            })}
          />
          <TextInput
            className='w-56'
            inline={false}
            errorUnderline={true}
            label={'Event Label'}
            {...register(`actions.${index}.actionItem.eventType` as const, {
              required: 'Monitor event label is required',
              validate: (value: string) => {
                if (value.length === 0 && value.length >= 17) return 'Must be 17 characters or fewer';
                return true;
              },
            })}
            errorMessage={indexError?.actionItem?.eventType?.message}
          />
        </div>
      </div>
    </div>
  );
};

export default DestinationMonitor;
