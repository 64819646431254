import React from 'react';
import { type UseFieldArrayRemove, useFormContext } from 'react-hook-form';
import TextInput from '@/modules/components/form/TextInput';
import { RemoveIcon } from '@/common/assets/icons';
import Textarea from '@/modules/components/form/Textarea';
import EmailList from '../../components/EmailList';

interface DestinationEmailProps {
  index: number;
  removeAction: UseFieldArrayRemove;
}

const DestinationEmail: React.FC<DestinationEmailProps> = ({ index, removeAction }) => {
  const {
    register,
    formState: { errors },
  }: any = useFormContext();

  const indexError = errors?.actions?.[index];

  return (
    <div className='flex space-x-4 space-y-4'>
      <div className='flex flex-row w-12 py-2.5 justify-center'>
        <button
          type='button'
          onClick={() => {
            removeAction(index);
          }}
          className='mt-11 text-emphasis-primary flex flex-row'
        >
          <RemoveIcon />
        </button>
      </div>
      <div className={`flex flex-row w-full pb-4 ${index !== 0 && 'border-t border-emphasis-base'}`}>
        <div className='flex flex-col py-10 w-40'>
          <div className='text-md text-emphasis-base'>Email</div>
        </div>
        <div className='flex flex-col space-y-5 py-2.5'>
          <input type='hidden' {...register(`actions.${index}.id` as const)} />
          <input type='hidden' {...register(`actions.${index}.type` as const)} />
          <input type='hidden' {...register(`actions.${index}.actionItem.id` as const)} />
          <input type='hidden' {...register(`actions.${index}.actionItem.type` as const)} />
          <div className='flex flex-1 py-2'>
            <EmailList index={index} />
          </div>
          <div className='flex flex-1 py-2'>
            <TextInput
              className='w-56'
              inline={false}
              label={'Email subject'}
              errorUnderline={true}
              {...register(`actions.${index}.actionItem.subject` as const, {
                required: 'Email subject is required',
              })}
              errorMessage={indexError?.actionItem?.subject?.message}
            />
          </div>
          <div className='flex flex-1 py-2'>
            <Textarea
              className='w-80'
              inline={false}
              label={'Email body'}
              errorUnderline={true}
              rows={5}
              {...register(`actions.${index}.actionItem.body` as const, {
                required: 'Email body is required',
              })}
              errorMessage={indexError?.actionItem?.body?.message}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DestinationEmail;
