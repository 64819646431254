import React, { type Ref, useMemo } from 'react';
import classNames from 'classnames';
import ErrorPopper from '@/modules/components/popper/ErrorPopper';
import { useForwardRef } from '@/common';

interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  name?: string;
  label?: string | JSX.Element;
  className?: string;
  inline?: boolean;
  errorMessage?: string;
  errorUnderline?: boolean;
  rows?: number;
  cols?: number;
}

const Textarea = (props: TextAreaProps, ref: Ref<HTMLTextAreaElement>): JSX.Element => {
  const { name, errorMessage, label, inline, className, rows, cols, errorUnderline, ...rest } = props;
  const textAreaRef = useForwardRef<HTMLTextAreaElement>(ref);
  const isInline = useMemo(() => inline !== undefined && inline, [inline]);

  const hasError = useMemo(() => {
    return errorMessage !== undefined && errorMessage !== '' && errorMessage !== null;
  }, [errorMessage]);

  return (
    <div className={classNames('text-emphasis-light font-normal text-base', { flex: isInline })}>
      <label htmlFor={name} className='block leading-6'>
        {label}
      </label>
      <div className='relative flex flex-col'>
        <textarea
          ref={textAreaRef}
          name={name}
          rows={rows}
          cols={cols}
          className={classNames(
            className,
            hasError ? 'ring-status-error-1 focus:ring-status-error-1' : 'ring-input-muted focus:ring-input-focus',
            !isInline && 'mt-2',
            'block px-2 rounded-md border-0 ring-1 ring-inset focus:outline-none focus:ring-2 focus:border-transparent',
          )}
          aria-invalid={hasError}
          {...rest}
        />
        {(errorUnderline ?? false) && hasError && (
          <div className='w-full'>
            <span className='text-status-error text-sm absolute left-0'>{errorMessage}</span>
          </div>
        )}
      </div>
      {!(errorUnderline ?? false) && (
        <ErrorPopper anchorElement={textAreaRef.current} visible={hasError}>
          <span>{errorMessage}</span>
        </ErrorPopper>
      )}
    </div>
  );
};

export default React.forwardRef(Textarea);
