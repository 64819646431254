import { type Target, TargetGranularity, TargetType } from '@/stores/_stores/targetConfigs';
import { flattenObject, getDirtyFormData, useAppDispatch, useAppSelector, useCurrentStore } from '@/common';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { selectClientConfig } from '@/stores/clientConfigs';
import TargetInterval from '@/modules/settings/targets/interval';
import React, { useCallback, useMemo } from 'react';
import { ContainedButton, TextButton } from '@/modules/components/form/Buttons';
import confirm from '@/modules/components/confirmDialog/ConfirmDialog';
import map from 'lodash/map';
import { updateTarget } from '@/stores/_stores/actions';
import { useJitsu } from '@jitsu/jitsu-react';

interface TargetFormProps {
  targets?: Target[];
}

const defaultInterval = {
  monday: { '00:00': 0 },
  tuesday: { '00:00': 0 },
  wednesday: { '00:00': 0 },
  thursday: { '00:00': 0 },
  friday: { '00:00': 0 },
  saturday: { '00:00': 0 },
  sunday: { '00:00': 0 },
};

const TargetForm = (props: TargetFormProps): JSX.Element => {
  const { targets = [] } = props;
  const dispatch = useAppDispatch();
  const currentStore = useCurrentStore();
  const clientConfig = useAppSelector(selectClientConfig);
  const { analytics } = useJitsu();

  const targetValues = useMemo(() => {
    if (targets === undefined || targets === null || targets.length === 0) {
      return [
        {
          type: TargetType.DepartureCount,
          granularity: TargetGranularity.Fifteen,
          interval: defaultInterval,
          adjustedInterval: defaultInterval,
        },
      ];
    }

    return targets;
  }, [targets]);

  const methods = useForm<{ targets: Target[] }>({
    defaultValues: { targets: [] },
    values: {
      targets: targetValues,
    },
    shouldUnregister: true,
  });

  const { fields } = useFieldArray({
    control: methods.control,
    name: 'targets',
  });

  const discardChanges = useCallback(async () => {
    const isConfirmed = (await confirm({
      title: 'Discard changes?',
      message: (
        <div className='grid grid-cols-1 gap-y-4 min-w-96'>
          <span className='flex justify-center'>Are you sure you want to discard changes?</span>
          <span className='flex justify-center'>This will revert to the last saved version.</span>
        </div>
      ),
    })) as boolean;

    if (!isConfirmed) {
      return;
    }

    methods.reset();
  }, [methods]);

  const onSubmit = async (data: { targets: Record<number, Target> }): Promise<void> => {
    // Only submit dirty fields
    const dirtyData = getDirtyFormData(data, methods.formState.dirtyFields);
    dirtyData.targets = map(dirtyData.targets, (target) => {
      return { ...target, lastUpdated: new Date().toISOString() };
    });
    const flattenedData = flattenObject(dirtyData);

    if (flattenedData !== null && currentStore !== undefined && clientConfig !== undefined) {
      await dispatch(
        updateTarget({
          storeId: currentStore.storeId,
          clientId: clientConfig.id,
          payload: { ...flattenedData, 'targets.0.type': TargetType.DepartureCount, 'targets.0.granularity': TargetGranularity.Fifteen },
        }),
      );
      analytics.track('targets_targetsUpdated', { target: { updatedTargets: dirtyData.targets } });
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        className='space-y-6'
        onSubmit={methods.handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') e.preventDefault();
        }}
      >
        <>
          {/* Form control buttons */}
          <div className='mt-6 flex items-center justify-end gap-x-6'>
            <TextButton
              disabled={methods.formState.isSubmitting || !methods.formState.isDirty}
              onClick={(e) => {
                e.preventDefault();
                void discardChanges();
              }}
            >
              Discard changes
            </TextButton>
            <ContainedButton type='submit' disabled={methods.formState.isSubmitting || !methods.formState.isDirty}>
              Save
            </ContainedButton>
          </div>

          {/* Targets */}
          {fields.map((field, index) => (
            <div key={field.id}>
              {field.granularity !== TargetGranularity.Fixed ? (
                <div className='flex text-base border-t border-divider-muted pt-4 gap-x-28'>
                  <label className='block leading-6 font-medium text-emphasis-secondary'>Target type</label>
                  <div className='relative font-normal text-emphasis-loud flex-grow space-y-6'>
                    <TargetInterval targetIndex={index} />
                  </div>
                </div>
              ) : (
                // TODO: UI for fixed target
                <></>
              )}
            </div>
          ))}
        </>
      </form>
    </FormProvider>
  );
};

export default TargetForm;
