import Header, { Title } from '@/modules/components/header';
import ContentPanel from '@/modules/components/contentPanel';
import HelpDeskForm from '@/modules/help/HelpDeskForm';

const Help = (): JSX.Element => {
  return (
    <>
      <Header>
        <Title>Eyecue Help</Title>
      </Header>
      <ContentPanel>
        <div className='flex flex-col items-center justify-center mt-16 space-y-12'>
          <span className='text-emphasis-primary font-normal text-[25px]'>What's the problem?</span>
          <HelpDeskForm />
        </div>
      </ContentPanel>
    </>
  );
};

export default Help;
