import { useAuth, useCurrentStore } from '@/common';
import { useCallback, useEffect, useState } from 'react';

import { get } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@/types';
import type { Store } from '@/stores/clientConfigs';

export type Resources = 'dashboard' | 'targets' | 'reports' | 'alerts';
export type Perm = 'view' | 'full-access';

const getLogger = (): ((message?: any, ...optionalParams: any[]) => void) => get(window, ['logger', 'debug']) ?? console.debug;

export const getSiteOrg = (siteId: string): string => {
  const tok = /fm-(.{3})-(.{3})-(\d{4,5})/.exec(siteId);
  if (tok !== null && tok.length > 1) {
    return tok[1];
  } else {
    return '';
  }
};

export const checkPermissions = (resource: Resources, perms: Perm[], storeId: string, userPerms: Record<string, Record<string, string[]>>): boolean => {
  const org = getSiteOrg(storeId);
  let isAllowed = false;
  for (const perm of perms) {
    if ((get(userPerms, [`${perm}:${resource}`, org]) ?? []).includes(storeId) || (get(userPerms, [`${perm}:${resource}`, org]) ?? []).includes('*')) {
      isAllowed = true;
      break;
    }
  }

  return isAllowed;
};

export const getAllAccesses = (storeId: string, userPerms: Record<string, Record<string, string[]>>): string[] => {
  const org = getSiteOrg(storeId);
  const accesses: string[] = [];
  Object.keys(userPerms).forEach((access) => {
    if ((get(userPerms, [access, org]) ?? []).includes(storeId) || (get(userPerms, [access, org]) ?? []).includes('*')) {
      accesses.push(access);
    }
  });

  return accesses;
};

export const filterSitesWithAccess = (stores: Store[], userPerms: Record<string, Record<string, string[]>>): Store[] =>
  stores.filter((store) => getAllAccesses(store.storeId, userPerms).length > 0);

export const usePermissions = (): {
  hasPermission: (res: Resources, perms: Perm[], siteId?: string) => boolean;
  getAccess: (storeId: string) => string[];
  getSitesWithAccess: (stores: Store[]) => Store[];
} => {
  const { user } = useAuth();

  const namespace = process.env.REACT_APP_IDENTITY_NAMESPACE ?? 'https://www.fingermark.tech';
  const subNamespace = process.env.REACT_APP_IDENTITY_SUBNAMESPACE ?? 'databoard';
  const [abacEnabled, setAbacEnabled] = useState(true);
  const [userPerms, setUserPerms] = useState({});
  const currentStore = useCurrentStore();

  const { [FeatureFlags.eyecuePortalUseAbac]: eyecuePortalUseAbac = true } = useFlags();

  useEffect(() => {
    const log = getLogger();

    if (eyecuePortalUseAbac === false) {
      log('feature-flag disabled, eyecuePortalUseAbac');
      setAbacEnabled(false);
      return;
    }

    if (user !== undefined) {
      if ('iq_data' in user[`${namespace}/${subNamespace}/app_metadata`] && 'v1' in user[`${namespace}/${subNamespace}/app_metadata`]['iq_data']) {
        setAbacEnabled(true);
      } else {
        setAbacEnabled(false);
      }
      const perms = get(user[`${namespace}/${subNamespace}/app_metadata`], ['iq_data', 'v1']);
      log({ perms });
      setUserPerms(perms);
    }
  }, [user, eyecuePortalUseAbac, setAbacEnabled, setUserPerms]);

  const hasPermission = useCallback(
    (resource: Resources, perms: Perm[], storeId?: string | undefined) => {
      const log = getLogger();
      log('checking perms', { resource, perms, storeId, userPerms, eyecuePortalUseAbac, abacEnabled });

      if (eyecuePortalUseAbac === false) {
        log('feature-flag disabled, eyecuePortalUseAbac');
        return true;
      } else {
        log('feature-flag enabled, eyecuePortalUseAbac');
      }

      if (!abacEnabled) {
        log('abac disabled for user');
        return true;
      } else {
        log('abac enabled for user');
      }

      let store = storeId;
      if (store === undefined) {
        if (currentStore === undefined) {
          log('perms store not defined');
          return false;
        }

        store = currentStore.storeId;
      }

      return checkPermissions(resource, perms, store, userPerms);
    },
    [userPerms, currentStore, eyecuePortalUseAbac, abacEnabled],
  );

  const getAccess = useCallback(
    (storeId: string) => {
      const log = getLogger();
      log('has access', { storeId, userPerms, eyecuePortalUseAbac, abacEnabled });

      if (eyecuePortalUseAbac === false) {
        log('feature-flag disabled, eyecuePortalUseAbac');
        return ['*'];
      } else {
        log('feature-flag enabled, eyecuePortalUseAbac');
      }

      if (!abacEnabled) {
        log('abac disabled for user');
        return ['*'];
      } else {
        log('abac enabled for user');
      }

      return getAllAccesses(storeId, userPerms);
    },
    [userPerms, eyecuePortalUseAbac, abacEnabled],
  );

  const getSitesWithAccess = useCallback(
    (stores: Store[]): Store[] => {
      const log = getLogger();
      log('getSitesWithAccess from', { stores });

      if (eyecuePortalUseAbac === false) {
        log('feature-flag disabled, getSitesWithAccess');
        return stores;
      } else {
        log('feature-flag enabled, getSitesWithAccess');
      }

      if (!abacEnabled) {
        log('abac disabled for user');
        return stores;
      } else {
        log('abac enabled for user');
      }

      const accessSites = filterSitesWithAccess(stores, userPerms);
      log('getSitesWithAccess', { accessSites });
      return accessSites;
    },
    [eyecuePortalUseAbac, abacEnabled, getAccess, userPerms],
  );

  return {
    hasPermission,
    getAccess,
    getSitesWithAccess,
  };
};
