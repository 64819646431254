import { useAppSelector } from '@/common';
import Header, { Title } from '@/modules/components/header';
import { selectFilteredStores, selectIsStoreLoading } from '@/stores/clientConfigs';
import { NavLink, useParams } from 'react-router-dom';
import {
  compileNavigation,
  dashboardNavigation,
  helpNavigation,
  monitorNavigation,
  reportsNavigation,
  screenNavigation,
  settingsNavigation,
  triggersNavigation,
} from '@/layouts/model';
import { useCallback, useState } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import ContentPanel from '../components/contentPanel';
import { FeatureFlags } from '@/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { usePermissions } from '../hooks';

const Store = (): JSX.Element => {
  const [filterText, setFilterText] = useState('');
  const stores = useAppSelector(selectFilteredStores(filterText));
  const isLoading = useAppSelector(selectIsStoreLoading);
  const { clientId } = useParams();
  const { hasPermission, getSitesWithAccess } = usePermissions();

  const {
    [FeatureFlags.eyecueAdminUseReports]: eyecueAdminUseReports = false,
    [FeatureFlags.eyecueAdminUseSettingsTarget]: eyecueAdminUseSettingsTarget = false,
    [FeatureFlags.eyecueAdminUseTriggerBuilder]: eyecueAdminUseTriggerBuilder = false,
    [FeatureFlags.eyecueAdminUseHelp]: eyecueAdminUseHelp = false,
  } = useFlags();

  const navigations = useCallback(
    (storeId: string) => {
      const reports = (eyecueAdminUseReports as boolean) ? [reportsNavigation] : [];
      const screen = [screenNavigation];
      const dashboard = [dashboardNavigation];
      const monitor = [monitorNavigation];
      const settings = (eyecueAdminUseSettingsTarget as boolean) ? [settingsNavigation] : [];
      const triggers = (eyecueAdminUseTriggerBuilder as boolean) ? [triggersNavigation] : [];
      const help = (eyecueAdminUseHelp as boolean) ? [helpNavigation] : [];

      return [
        ...(hasPermission('reports', ['view', 'full-access'], storeId) ? reports : []),
        ...(hasPermission('alerts', ['view', 'full-access'], storeId) ? triggers : []),
        ...screen,
        ...(hasPermission('dashboard', ['view', 'full-access'], storeId) ? dashboard : []),
        ...(hasPermission('alerts', ['view', 'full-access'], storeId) ? monitor : []),
        ...(hasPermission('targets', ['full-access'], storeId) ? settings : []),
        ...help,
      ];
    },
    [eyecueAdminUseTriggerBuilder, eyecueAdminUseSettingsTarget, hasPermission],
  );

  const storeCompiledNavigationModel = useCallback(
    (storeId: string) => compileNavigation(navigations(storeId), { storeId, clientId: clientId ?? '' }),
    [navigations],
  );

  return (
    <>
      <Header>
        <Title>Stores</Title>
        <div className='flex flex-1 justify-end'>
          <div className='relative mt-2 flex items-center'>
            <input
              type='text'
              name='search'
              className='text-emphasis-solid ring-gray-300 placeholder:text-emphasis-base focus:ring-indigo-600 block w-full rounded-full border-0 py-1.5 pr-10 pl-4 ring-1 ring-inset focus:outline-none focus:ring-2 focus:border-transparent'
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
            />
            <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
              <MagnifyingGlassIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
            </div>
          </div>
        </div>
      </Header>
      <ContentPanel isLoading={isLoading} className='pl-12 pr-12 pt-6'>
        <div className='flex flex-wrap'>
          {getSitesWithAccess(stores).map((store) => {
            return (
              <div key={store.id} className='group relative flex flex-col overflow-hidden rounded-md border border-outline-primary w-[250px] m-5'>
                <ul role='list' className='divide-y divide-outline-primary'>
                  <li className='px-6 py-4 text-base font-semibold text-emphasis-overlay bg-menu-background'>{store.name}</li>
                  {storeCompiledNavigationModel(store.storeId).map((item) => (
                    <li key={item.name} className='px-6 py-4 text-base font-normal text-emphasis-primary'>
                      <NavLink className='flex items-center gap-x-3' to={item.href}>
                        <item.icon className='fill-emphasis-primary' />
                        {item.label}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            );
          })}
        </div>
      </ContentPanel>
    </>
  );
};

export default Store;
