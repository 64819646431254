import React, { useMemo } from 'react';
import TextInput from '@/modules/components/form/TextInput';
import { RemoveIcon } from '@/common/assets/icons';
import { type UseFieldArrayRemove, useFormContext } from 'react-hook-form';
import Dropdown from '@/modules/components/form/Dropdown';
import { comparatorOptions } from '../../common';
import { FeatureFlags } from '@/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isArray } from 'lodash';
import { type TriggerOption } from '@/stores/triggers/types';
import { insertEmptyOptionToDropDown } from '../helpers';

interface TimeInRoiProps {
  index: number;
  removeCondition: UseFieldArrayRemove;
  roiIds: TriggerOption[];
}

const TimeInRoi: React.FC<TimeInRoiProps> = ({ index, removeCondition }) => {
  const { [FeatureFlags.eyecueAdminTriggerBuilderTemporaryIdMapping]: eyecueAdminTriggerBuilderTemporaryIdMapping } = useFlags();
  const { roiIds } = useMemo(() => {
    const list = eyecueAdminTriggerBuilderTemporaryIdMapping?.roiIds;
    return {
      roiIds: list != null && isArray(list) ? list : [],
    };
  }, [eyecueAdminTriggerBuilderTemporaryIdMapping]);

  const {
    register,
    formState: { errors },
    setValue,
  }: any = useFormContext();

  const indexError = errors?.conditions?.[index];

  const dropDownError = useMemo(() => {
    if (indexError?.roiId != null) {
      return indexError?.roiId?.message;
    }
    if (indexError?.roiName != null) {
      return indexError?.roiName?.message;
    }
  }, [indexError]);

  return (
    <div key={index} className='flex space-x-4'>
      <div className='flex flex-row w-12 py-4 justify-center'>
        <button
          type='button'
          onClick={() => {
            removeCondition(index);
          }}
          className='mt-10 text-emphasis-primary flex flex-row'
        >
          <RemoveIcon />
        </button>
      </div>
      <div className={`flex flex-row w-full ${index !== 0 ? 'border-t border-emphasis-base' : ''}`}>
        <div className='flex flex-col py-10 w-40'>
          <div className='text-md text-emphasis-base mt-4'>Time in Activity Zone</div>
        </div>
        <div className='flex flex-row space-x-2 py-5'>
          <input type='hidden' {...register(`conditions.${index}.id` as const)} />
          <input type='hidden' {...register(`conditions.${index}.metric` as const)} />
          <input
            type='hidden'
            {...register(`conditions.${index}.roiName` as const, {
              required: 'Activity zone is required',
              validate: (value: string) => value.length > 0 || 'Activity zone name is required',
            })}
          />
          <div className='flex flex-col w-48'>
            <Dropdown
              options={insertEmptyOptionToDropDown(roiIds)}
              inline={false}
              errorUnderline={true}
              label={'Activity zone'}
              className='flex-1 w-full'
              {...register(`conditions.${index}.roiId` as const, {
                required: 'Activity zone is required',
                validate: (value: string) => value.length > 0 || 'Activity zone is required',
                onChange: (e: any) => {
                  setValue(`conditions.${index}.roiName`, roiIds.find((c) => c.value === e.target.value)?.label, { shouldDirty: true });
                },
              })}
              errorMessage={dropDownError}
            />
          </div>
          <Dropdown
            options={comparatorOptions}
            inline={false}
            label='Comparator'
            className='w-full flex-1'
            {...register(`conditions.${index}.comparator` as const)}
            errorMessage={indexError?.comparator}
          />
          <TextInput
            className='w-full flex-1'
            inline={false}
            type='number'
            errorUnderline={true}
            label={'Duration'}
            {...register(`conditions.${index}.threshold` as const, {
              required: 'Duration is required',
              validate: (value: number) => value > 0 || 'Duration is required',
              setValueAs: (value: string) => Number(value),
            })}
            errorMessage={indexError?.threshold?.message}
          />
        </div>
      </div>
    </div>
  );
};

export default TimeInRoi;
