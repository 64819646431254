import classNames from 'classnames';
import React, { type Ref, useMemo } from 'react';
import ErrorPopper from '../popper/ErrorPopper';
import { useForwardRef } from '@/common';

export interface Option<T> {
  label: string;
  value: T;
}

interface DropdownProps {
  label?: string;
  isInline?: boolean;
  options: Array<Option<string>>;
  defaultValue?: string;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  name?: string;
  className?: string;
  errorMessage?: string;
  errorUnderline?: boolean;
  inline?: boolean;
  width?: string;
  value?: string;
}

const Dropdown = (props: DropdownProps, ref: Ref<HTMLSelectElement>): JSX.Element => {
  const { label, options, name, className, errorMessage, inline, errorUnderline, ...rest } = props;
  const inputRef = useForwardRef<HTMLSelectElement>(ref);
  const isInline = useMemo(() => inline !== undefined && inline, [inline]);

  const hasError = useMemo(() => {
    return errorMessage !== undefined && errorMessage !== '' && errorMessage !== null;
  }, [errorMessage]);

  return (
    <div className={classNames('text-emphasis-light font-normal text-base', { 'flex items-center': isInline })}>
      <label htmlFor={name} className='block leading-6'>
        {label}
      </label>
      <div className='relative flex flex-col'>
        <select
          name={name}
          ref={inputRef}
          className={classNames(
            className,
            'block w-full text-md border-0 rounded-md focus:outline-none focus:ring-2 focus:border-transparent',
            'rounded-md border-0 ring-1 ring-inset focus:outline-none focus:ring-2 focus:border-transparent',
            hasError ? 'ring-status-error-1 focus:ring-status-error-1' : 'ring-input-muted focus:ring-input-focus',
            !isInline && 'mt-2',
          )}
          aria-invalid={hasError}
          {...rest}
        >
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        {(errorUnderline ?? false) && hasError && (
          <div className='w-full'>
            <span className='text-status-error text-sm absolute left-0'>{errorMessage}</span>
          </div>
        )}
      </div>
      {!(errorUnderline ?? false) && (
        <ErrorPopper anchorElement={inputRef.current} visible={hasError}>
          <span>{errorMessage}</span>
        </ErrorPopper>
      )}
    </div>
  );
};

export default React.forwardRef(Dropdown);
