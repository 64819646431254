import { Outlet, useLocation, useParams } from 'react-router-dom';
import { Sidebar, SidebarMenuItem } from './components/sidebar';
import {
  compileNavigation,
  dashboardNavigation,
  logoutNavigation,
  monitorNavigation,
  reportsNavigation,
  screenNavigation,
  storesNavigation,
  settingsNavigation,
  triggersNavigation,
  helpNavigation,
} from './model';
import { useCallback, useEffect } from 'react';
import { FeatureFlags } from '@/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { usePermissions } from '@/modules/hooks';
import { useJitsu } from '@jitsu/jitsu-react';

const DesktopMasterLayout = (): JSX.Element => {
  const { storeId, clientId } = useParams();
  const { analytics } = useJitsu();
  const location = useLocation();

  // Create Jitsu 'page' event when user navigates to a new page
  useEffect(() => {
    analytics.page();
  }, [location]);

  const {
    [FeatureFlags.eyecueAdminUseReports]: eyecueAdminUseReports = false,
    [FeatureFlags.eyecueAdminUseSettingsTarget]: eyecueAdminUseSettingsTarget = false,
    [FeatureFlags.eyecueAdminUseTriggerBuilder]: eyecueAdminUseTriggerBuilder = false,
    [FeatureFlags.eyecueAdminUseHelp]: eyecueAdminUseHelp = false,
  } = useFlags();

  const { hasPermission } = usePermissions();

  const navigations = useCallback(() => {
    const logout = [logoutNavigation];
    const stores = [storesNavigation];
    const reports = (eyecueAdminUseReports as boolean) ? [reportsNavigation] : [];
    const screen = [screenNavigation];
    const dashboard = [dashboardNavigation];
    const monitor = [monitorNavigation];
    const settings = (eyecueAdminUseSettingsTarget as boolean) ? [settingsNavigation] : [];
    const triggers = (eyecueAdminUseTriggerBuilder as boolean) ? [triggersNavigation] : [];
    const help = (eyecueAdminUseHelp as boolean) ? [helpNavigation] : [];

    return [
      ...logout,
      ...stores,
      ...(hasPermission('reports', ['view', 'full-access']) ? reports : []),
      ...(hasPermission('alerts', ['view', 'full-access']) ? triggers : []),
      ...screen,
      ...(hasPermission('dashboard', ['view', 'full-access']) ? dashboard : []),
      ...(hasPermission('alerts', ['view', 'full-access']) ? monitor : []),
      ...(hasPermission('targets', ['full-access']) ? settings : []),
      ...help,
    ];
  }, [eyecueAdminUseTriggerBuilder, eyecueAdminUseReports, hasPermission]);

  const navigationModel = useCallback(() => {
    return compileNavigation(navigations(), { storeId: storeId ?? '', clientId: clientId ?? '' });
  }, [storeId, navigations]);

  return (
    <>
      <Sidebar>
        {navigationModel().map((item) => (
          <SidebarMenuItem key={`${item.name}`} {...item} />
        ))}
      </Sidebar>
      <main className='pl-20 5xl:pl-72'>
        <Outlet />
      </main>
    </>
  );
};

export default DesktopMasterLayout;
