import { combineReducers, configureStore, type PreloadedState } from '@reduxjs/toolkit';
import clientConfigsReducer from './clientConfigs';
import dashboardsReducer from './dashboards';
import devicesReducer from './devices';
import triggersReducer from './triggers';
import targetConfigsReducer from './_stores/targetConfigs';
import generalStoreConfigReducer from './_stores/generalConfigs';
import dashboardTemplatesReducer from './dashboardTemplates';

// Create the root reducer separately so we can extract the RootState type
const rootReducer = combineReducers({
  clientConfigs: clientConfigsReducer,
  dashboards: dashboardsReducer,
  devices: devicesReducer,
  triggers: triggersReducer,
  targetConfigs: targetConfigsReducer,
  generalStoreConfigs: generalStoreConfigReducer,
  dashboardTemplates: dashboardTemplatesReducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>): any => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};

export const store = setupStore();
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
export type Selector<S> = (state: RootState) => S;
