import { useLDClient, withLDProvider } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { updateLaunchDarklyContext } from './common';

const App = (): JSX.Element => {
  const { storeId = '' } = useParams();
  const ldClient = useLDClient();

  const version = process.env.REACT_APP_VERSION;

  window.logger.info(`EYECUE Control Center v${version}`);

  useEffect(() => {
    updateLaunchDarklyContext(ldClient, { 'site-id': { key: storeId } });
  }, [storeId, ldClient]);

  return (
    <div className='theme-light'>
      <Outlet />
      <div id='modal-root' />
    </div>
  );
};

const launchdarklyContext = localStorage.getItem('launchdarkly.context');

export default withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_KEY ?? '',
  context: JSON.parse(launchdarklyContext ?? '{}'),
  options: { streaming: false },
})(App);
