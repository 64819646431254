import React, { useCallback, useMemo } from 'react';
import DestinationDashboard from './Dashboard';
import MonitorDashboard from './Monitor';
import EmailDashboard from './Email';
import { UseFieldArrayRemove } from 'react-hook-form';
import { type MappedAction, ActionTypes } from '../../../../stores/triggers/types';
import { actionTypes } from '../../common';
import NewItemPicker from '../../components/NewItemPicker';
import { useAppSelector } from '@/common';
import { selectDashboards } from '@/stores/dashboards';

interface TriggerActionsProps {
  fields: MappedAction[];
  addAction: (type: string) => void;
  removeAction: UseFieldArrayRemove;
}

const Destinations: React.FC<TriggerActionsProps> = ({ fields, addAction, removeAction }) => {
  const dashboards = useAppSelector(selectDashboards);
  const renderActions = useCallback(
    (action: any, index: number) => {
      switch (action.type) {
        case ActionTypes.NOTIFICATION:
          return <DestinationDashboard key={index} index={index} removeAction={removeAction} />;
        case ActionTypes.MONITOR:
          return <MonitorDashboard key={index} index={index} removeAction={removeAction} />;
        case ActionTypes.EMAIL:
          return <EmailDashboard key={index} index={index} removeAction={removeAction} />;
        default:
          return null;
      }
    },
    [fields, addAction, removeAction],
  );

  const filterdActionTypes = useMemo(() => {
    return actionTypes.filter((item: any) => {
      if (item.value === ActionTypes.NOTIFICATION) {
        return dashboards.length > 0;
      }
      if (item.value === ActionTypes.MONITOR) {
        return fields.find((field) => field.type === ActionTypes.MONITOR) == null;
      }
      return true;
    });
  }, [actionTypes, fields, dashboards]);

  return (
    <div className='flex border-t border-emphasis-base'>
      <div className='flex relative w-12'>
        <h3 className='mt-5 text-lg font-semibold text-emphasis-primary'>Then</h3>
      </div>
      <div className='flex flex-col'>
        {fields.length !== 0 && <div className='flex flex-col mt-8'>{fields.map((condition: any, index: number) => renderActions(condition, index))}</div>}
        <div className='flex space-x-4'>
          <div className='flex flex-row w-12'></div>
          <div className={`flex flex-col w-full pb-4 ${fields.length !== 0 && 'border-t border-emphasis-base'}`}>
            <NewItemPicker numberOfItems={fields.length} items={filterdActionTypes} handleAddItem={addAction} type='destination' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Destinations;
