import { type NavigationModel } from './components/sidebar';
import { DashboardIcon, HomeIcon, LogoutIcon, MonitorIcon, HelpIcon, ReportsIcon, ScreenIcon, SettingsIcon, TriggersIcon } from '@/common/assets/icons';
import template from 'lodash/template';

export interface NavigationTemplate {
  label: string;
  name: string;
  href: string;
  icon?: any;
}

export const logoutNavigation: NavigationTemplate = { label: 'Logout', name: 'logout', href: '/admin/${clientId}/logout', icon: LogoutIcon };
export const storesNavigation: NavigationTemplate = { label: 'Stores', name: 'stores', href: '/admin/${clientId}/stores', icon: HomeIcon };
export const reportsNavigation: NavigationTemplate = { label: 'Reports', name: 'reports', href: '/admin/${clientId}/${storeId}/reports', icon: ReportsIcon };
export const triggersNavigation: NavigationTemplate = { label: 'Triggers', name: 'triggers', href: '/admin/${clientId}/${storeId}/triggers', icon: TriggersIcon };
export const screenNavigation: NavigationTemplate = { label: 'Screens', name: 'screens', href: '/admin/${clientId}/${storeId}/screens', icon: ScreenIcon };
export const dashboardNavigation: NavigationTemplate = { label: 'Dashboards', name: 'dashboards', href: '/admin/${clientId}/${storeId}/dashboards', icon: DashboardIcon };
export const monitorNavigation: NavigationTemplate = { label: 'Monitor', name: 'monitor', href: '/admin/${clientId}/${storeId}/monitor', icon: MonitorIcon };
export const settingsNavigation: NavigationTemplate = { label: 'Settings', name: 'settings', href: '/admin/${clientId}/${storeId}/settings', icon: SettingsIcon };
export const helpNavigation: NavigationTemplate = { label: 'Help', name: 'help', href: '/admin/${clientId}/${storeId}/help', icon: HelpIcon };

// Return false if any of the href template params are undefined, null or empty
// Return true otherwise
export const validateHrefTemplate = (href: string, params: Record<string, string>): boolean => {
  const regex = /\${([^}]+)}/g;
  const matches = href.match(regex);

  if (matches === null || matches.length === 0) {
    return true;
  }

  return matches.every((match: string): boolean => {
    const key = match.replace('${', '').replace('}', '');
    return params[key] !== undefined && params[key] !== null && params[key] !== '';
  });
};

export const compileNavigation = (navigationTemplate: NavigationTemplate[], params: Record<string, string>): NavigationModel[] => {
  return navigationTemplate.map((item: NavigationTemplate): NavigationModel => {
    const compiledNavigationItem: NavigationModel = { ...item };
    const compiled = template(item.href);

    compiledNavigationItem.disabled = !validateHrefTemplate(item.href, params);
    compiledNavigationItem.href = compiled(params);

    return compiledNavigationItem;
  });
};
