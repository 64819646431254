import { createSlice, createAsyncThunk, type ActionReducerMapBuilder, createSelector } from '@reduxjs/toolkit';
import get from 'lodash/get';
import createHttp from '@/services/http';
import { REQUEST_STATUS, type State } from '@/types';
import { fulfilledReducer, pendingReducer, rejectedReducer } from '../utils';
import { isEmpty } from '@/common';
import { type SiteTriggers, type MappedSiteTriggers, type MappedSiteTrigger } from '@/stores/triggers/types';
import { type Selector, type RootState } from '..';
import { mappedSiteTriggers } from './helpers';
import { getNewTrigger } from '@/modules/triggers/edit/helpers';

export interface SiteTriggerState extends State<SiteTriggers> {}

const initialState: SiteTriggerState = {
  data: {} as SiteTriggers,
  requestStatus: REQUEST_STATUS.IDLE,
  error: null,
};

const getSiteTriggersQuery = `
  query getSiteTriggers($siteId:String!){
    getSiteTriggers(siteId: $siteId) {
      siteId
      organisationId
      organisationName
      lastUpdatedTimestamp
      version
      conditions {
        id
        metric
        ... on ConditionTimeInRoi {
          targetType
          roiId
          roiName
          threshold
          comparator
        }
        ... on ConditionChipBayPercentage {
          targetType
          chipBayId
          chipBayName
          duration
          threshold
          comparator
        }
        ... on ConditionTableStatus {
          status
          tableId
          tableName
          duration
        }
      }
      destinations {
        id
        type
        ... on DestinationDashboard {
          siteId
          organisationId
          dashboardId
        }
        ... on DestinationEmail {
          address
        }
      }
      triggers {
        id
        title
        actions
        conditions
        enabled
      }
      actions {
        id
        type
        actionItem
        ... on ActionEmail {
          destinations  
        }
        ... on ActionNotification {
          destinations  
        }
      }
      actionItems {
        id
        type
        ... on ActionItemMonitor {
          eventType
          cameraIds
          preRoll
          postRoll
        }
        ... on ActionItemEmail {
          subject
          body
        }
        ... on ActionItemNotification {
          title
          description
          ttl
          level
        }
      }
    }
  }`;

export const getSiteTriggers = createAsyncThunk<SiteTriggers, { storeId: string }>('triggers/getSiteTriggers', async ({ storeId }): Promise<SiteTriggers> => {
  const response = await createHttp().post(process.env.REACT_APP_MIMIR_API ?? '', {
    query: getSiteTriggersQuery,
    variables: {
      siteId: storeId,
    },
  });

  const trigger = get(response, 'data.data.getSiteTriggers', null);

  if (isEmpty(trigger)) {
    window.logger.warn(`No trigger found for site ${storeId}`);
    return {} as SiteTriggers;
  }

  return trigger;
});

export const triggersSlice = createSlice({
  name: 'triggers',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<SiteTriggerState>) => {
    builder
      .addCase(getSiteTriggers.pending, pendingReducer)
      .addCase(
        getSiteTriggers.fulfilled,
        fulfilledReducer((state, action): void => {
          state.data = action.payload as SiteTriggers;
        }),
      )
      .addCase(getSiteTriggers.rejected, rejectedReducer);
  },
});

export default triggersSlice.reducer;

export const selectSiteTriggers = (state: RootState): SiteTriggers => state.triggers.data;

export const selectSiteTriggersMapped = (state: RootState): MappedSiteTriggers => {
  return mappedSiteTriggers(state.triggers.data);
};

export const selectSiteTriggerById = (triggerId: string, storeId: string, organisationId: string, organisationName: string): Selector<MappedSiteTrigger> =>
  createSelector(selectSiteTriggersMapped, (siteTriggers): MappedSiteTrigger => {
    const siteTrigger = siteTriggers.triggers.find((trigger) => trigger.id === triggerId);
    if (isEmpty(siteTrigger)) {
      return {} as MappedSiteTrigger;
    }
    return {
      siteId: storeId,
      organisationId,
      organisationName,
      lastUpdatedTimestamp: siteTriggers.lastUpdatedTimestamp,
      trigger: isEmpty(siteTrigger) ? getNewTrigger(triggerId) : siteTrigger,
    };
  });

export const selectIsTriggersLoading = (state: RootState): boolean => state.triggers.requestStatus === REQUEST_STATUS.LOADING;
