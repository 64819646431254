import {
  type SiteTriggers,
  type MappedSiteTriggers,
  type ActionItem,
  type Action,
  type Trigger,
  type MappedTrigger,
  type ActionTypes,
  type SiteTriggersInput,
  type ActionEmail,
  type FormValues,
} from './types';
import { cloneDeep, isArray } from 'lodash';

export const mappedSiteTriggers = (data: SiteTriggers): MappedSiteTriggers => {
  return {
    siteId: data.siteId,
    organisationId: data.organisationId,
    organisationName: data.organisationName,
    lastUpdatedTimestamp: data?.lastUpdatedTimestamp ?? '',
    triggers:
      isArray(data?.triggers) && data?.triggers?.length > 0
        ? (data.triggers.map((trigger: Trigger) => ({
            ...trigger,
            conditions: trigger.conditions.map((sourceId) => (isArray(data.conditions) ? data.conditions : []).find((item) => item.id === sourceId)),
            actions: trigger.actions
              .map((sourceId) => (isArray(data.actions) ? data.actions : []).find((item) => item.id === sourceId))
              .map((action: any) => ({
                ...action,
                actionItem: (isArray(data.actionItems) ? data.actionItems : []).find((item: ActionItem) => item.id === action?.actionItem),
                destinations: (action.destinations != null ? action.destinations : []).map((sourceId: string) =>
                  (isArray(data.destinations) ? data.destinations : []).find((item: any) => item.id === sourceId),
                ),
              })),
          })) as MappedTrigger[])
        : [],
  };
};

export const mergeMappedTrigger = (orgSiteTriggers: SiteTriggers, orgMappedTrigger: FormValues): SiteTriggersInput => {
  const siteTriggers = cloneDeep({
    ...orgSiteTriggers,
    organisationId: orgMappedTrigger.organisationId,
    organisationName: orgMappedTrigger.organisationName,
  });
  const mappedTrigger = cloneDeep(orgMappedTrigger);

  // Ensure triggers array exists
  if (siteTriggers.triggers == null) {
    siteTriggers.triggers = [];
  }

  // Update or add the trigger
  const existingTriggerIndex = siteTriggers.triggers.findIndex((t) => t.id === mappedTrigger.id);

  const updatedTrigger: Trigger = {
    id: mappedTrigger.id,
    title: mappedTrigger.title,
    enabled: mappedTrigger.enabled,
    actions: mappedTrigger.actions.map((a) => a.id),
    conditions: mappedTrigger.conditions.map((c) => c.id),
  };

  if (existingTriggerIndex >= 0) {
    siteTriggers.triggers[existingTriggerIndex] = updatedTrigger;
  } else {
    siteTriggers.triggers.push(updatedTrigger);
  }

  // Ensure conditions array exists
  if (siteTriggers.conditions == null) {
    siteTriggers.conditions = [];
  }

  // Update or add conditions
  (mappedTrigger.conditions != null && isArray(mappedTrigger.conditions) ? mappedTrigger.conditions : []).forEach((condition) => {
    if (siteTriggers.conditions != null) {
      const existingConditionIndex = siteTriggers.conditions.findIndex((c) => c.id === condition.id);
      if (existingConditionIndex >= 0) {
        siteTriggers.conditions[existingConditionIndex] = condition;
      } else {
        siteTriggers.conditions.push(condition);
      }
    }
  });

  // Ensure actions and actionItems arrays exist
  if (siteTriggers.actions == null) {
    siteTriggers.actions = [];
  }
  if (siteTriggers.actionItems == null) {
    siteTriggers.actionItems = [];
  }

  // Update or add actions and actionItems
  (mappedTrigger.actions != null && isArray(mappedTrigger.actions) ? mappedTrigger.actions : []).forEach((mappedAction: any) => {
    const actionItem = mappedAction.actionItem;
    const existingActionIndex = siteTriggers.actions?.findIndex((a) => a.id === mappedAction.id);
    const existingActionItemIndex = siteTriggers.actionItems?.findIndex((ai) => ai.id === actionItem.id);

    const updatedAction: Action = {
      id: mappedAction.id,
      type: mappedAction.type as ActionTypes,
      actionItem: actionItem.id,
      destinations: (mappedAction.destinations != null && isArray(mappedAction.destinations) ? mappedAction.destinations : []).map((d: any) => d.id),
    };

    if (existingActionIndex !== undefined && existingActionIndex >= 0) {
      siteTriggers.actions[existingActionIndex] = updatedAction;
    } else {
      siteTriggers.actions.push(updatedAction);
    }

    if (existingActionItemIndex !== undefined && existingActionItemIndex >= 0) {
      siteTriggers.actionItems[existingActionItemIndex] = actionItem;
    } else {
      siteTriggers.actionItems.push(actionItem);
    }

    // Ensure destinations array exists
    if (siteTriggers.destinations == null) {
      siteTriggers.destinations = [];
    }

    // Update or add destinations
    (mappedAction.destinations != null && isArray(mappedAction.destinations) ? mappedAction.destinations : [])?.forEach((destination: any) => {
      if (siteTriggers.destinations != null) {
        const existingDestinationIndex = siteTriggers.destinations.findIndex((d) => d.id === destination.id);
        if (existingDestinationIndex >= 0) {
          siteTriggers.destinations[existingDestinationIndex] = destination;
        } else {
          siteTriggers.destinations.push(destination);
        }
      }
    });
  });

  return siteTriggers as unknown as SiteTriggersInput;
};

export const getInitialMappedTrigger = (siteId: string, organisationId: string, organisationName: string): SiteTriggers =>
  ({
    siteId,
    organisationId,
    organisationName,
    conditions: [],
    destinations: [],
    triggers: [],
    actions: [],
    actionItems: [],
  }) as unknown as SiteTriggers;

export const removeTriggerAndRelatedObjects = (orgSiteTriggers: SiteTriggers, triggerId: string): SiteTriggersInput => {
  // Clone the site triggers to avoid mutating the original object
  const siteTriggers = cloneDeep(orgSiteTriggers);

  // Find the trigger to delete
  const triggerToDelete = (isArray(siteTriggers.triggers) ? siteTriggers.triggers : []).find((trigger) => trigger.id === triggerId);
  if (triggerToDelete == null) {
    // If the trigger does not exist, return the original site triggers
    return siteTriggers as unknown as SiteTriggersInput;
  }

  // Collect related objects
  const relatedConditions = new Set<string>();
  const relatedActions = new Set<string>();
  const relatedActionItems = new Set<string>();
  const relatedDestinations = new Set<string>();

  // Add related conditions
  triggerToDelete.conditions.forEach((conditionId: string) => {
    const condition = (isArray(siteTriggers.conditions) ? siteTriggers.conditions : []).find((c) => c.id === conditionId);
    if (condition != null) {
      relatedConditions.add(condition.id);
    }
  });

  // Add related actions and action items
  triggerToDelete.actions.forEach((actionId: string) => {
    const action = (isArray(siteTriggers.actions) ? siteTriggers.actions : []).find((a) => a.id === actionId) as ActionEmail;
    if (action != null) {
      relatedActions.add(action.id);
      relatedActionItems.add(action.actionItem);

      if (isArray(action.destinations)) {
        action.destinations.forEach((destinationId: string) => {
          relatedDestinations.add(destinationId);
        });
      }
    }
  });

  // Remove related objects
  siteTriggers.conditions = (isArray(siteTriggers.conditions) ? siteTriggers.conditions : []).filter((condition) => !relatedConditions.has(condition.id));

  siteTriggers.actions = (isArray(siteTriggers.actions) ? siteTriggers.actions : []).filter((action) => !relatedActions.has(action.id));

  siteTriggers.actionItems = (isArray(siteTriggers.actionItems) ? siteTriggers.actionItems : []).filter((actionItem) => !relatedActionItems.has(actionItem.id));

  siteTriggers.destinations = (isArray(siteTriggers.destinations) ? siteTriggers.destinations : []).filter(
    (destination) => !relatedDestinations.has(destination.id),
  );

  // Remove the trigger
  siteTriggers.triggers = (isArray(siteTriggers.triggers) ? siteTriggers.triggers : []).filter((trigger) => trigger.id !== triggerId);

  return siteTriggers as unknown as SiteTriggersInput;
};
