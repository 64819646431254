import React, { useMemo, type Ref } from 'react';
import classNames from 'classnames';

interface SelectInputProps extends React.InputHTMLAttributes<HTMLSelectElement> {
  label: string | JSX.Element;
  options: Array<{ value: string; label: string }>;
  inline?: boolean;
}

const SelectInput = (props: SelectInputProps, ref: Ref<HTMLSelectElement>): JSX.Element => {
  const { label, name, options, inline, className, ...rest } = props;
  const isInline = useMemo(() => inline !== undefined && inline, [inline]);

  return (
    <div className={classNames('text-emphasis-light font-normal text-base', isInline ? 'flex items-center' : '')}>
      <label htmlFor={name} className='block leading-6 whitespace-nowrap'>
        {label}
      </label>
      <select
        ref={ref}
        name={name}
        className={classNames(
          className,
          !isInline && 'mt-2',
          'block h-10 px-2 rounded-md border-0 ring-1 ring-inset ring-input-muted focus:outline-none focus:ring-2 focus:border-transparent focus:ring-input-focus disabled:bg-[#CCCFD0] disabled:cursor-not-allowed',
        )}
        {...rest}
      >
        <option value='' />
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default React.forwardRef(SelectInput);
