import React, { useMemo } from 'react';
import TextInput from '@/modules/components/form/TextInput';
import { RemoveIcon } from '@/common/assets/icons';
import { type UseFieldArrayRemove, useFormContext } from 'react-hook-form';
import Dropdown from '@/modules/components/form/Dropdown';
import { comparatorOptions } from '../../common';
import { type TriggerOption } from '@/stores/triggers/types';
import { insertEmptyOptionToDropDown } from '../helpers';

interface TimeInRoiProps {
  index: number;
  removeCondition: UseFieldArrayRemove;
  chipBayIds: TriggerOption[];
}

const ChipBayPercentage: React.FC<TimeInRoiProps> = ({ index, removeCondition, chipBayIds }) => {
  const {
    register,
    formState: { errors },
    setValue,
  }: any = useFormContext<any>();

  const indexError = errors?.conditions?.[index];

  const dropDownError = useMemo(() => {
    if (indexError?.chipBayId != null) {
      return indexError?.chipBayId?.message;
    }
    if (indexError?.chipBayName != null) {
      return indexError?.chipBayName?.message;
    }
  }, [indexError]);

  return (
    <div key={index} className='flex space-x-4'>
      <div className='flex flex-row w-12 py-4 justify-center'>
        <button
          type='button'
          onClick={() => {
            removeCondition(index);
          }}
          className='mt-10 text-emphasis-primary flex flex-row'
        >
          <RemoveIcon />
        </button>
      </div>
      <div className={`flex flex-row w-full ${index !== 0 ? 'border-t border-emphasis-base' : ''}`}>
        <div className='flex flex-col py-10 w-40'>
          <div className='text-md text-emphasis-base mt-4'>Chip Bay Percentage</div>
        </div>
        <div className='flex flex-row space-x-2 py-5'>
          <input type='hidden' {...register(`conditions.${index}.id` as const)} />
          <input type='hidden' {...register(`conditions.${index}.metric` as const)} />
          <input
            type='hidden'
            {...register(`conditions.${index}.chipBayName` as const, {
              required: 'Chip bay name is required',
              validate: (value: string) => value.length > 0 || 'Chip bay name is required',
            })}
          />
          <div className='flex flex-col w-48'>
            <Dropdown
              options={insertEmptyOptionToDropDown(chipBayIds)}
              inline={false}
              errorUnderline={true}
              label={'Activity zone'}
              className='flex-1 w-full'
              {...register(`conditions.${index}.chipBayId` as const, {
                required: 'Chip bay ID is required',
                validate: (value: string) => value.length > 0 || 'Chip bay ID is required',
                onChange: (e: any) => {
                  setValue(`conditions.${index}.chipBayName`, chipBayIds.find((c) => c.value === e.target.value)?.label, { shouldDirty: true });
                },
              })}
              errorMessage={dropDownError}
            />
          </div>
          <Dropdown
            options={comparatorOptions}
            inline={false}
            label='Comparator'
            className='w-full'
            {...register(`conditions.${index}.comparator` as const)}
            errorMessage={indexError?.comparator}
          />
          <TextInput
            className='w-full flex-1'
            inline={false}
            label='Duration'
            errorUnderline={true}
            type='number'
            {...register(`conditions.${index}.duration` as const, {
              required: 'Duration is required',
              setValueAs: (value: string) => Number(value),
              validate: (value: number) => value > 0 || 'Duration is required',
            })}
            errorMessage={indexError?.duration?.message}
          />
          <TextInput
            className='w-full flex-1'
            inline={false}
            label='Threshold'
            errorUnderline={true}
            type='number'
            {...register(`conditions.${index}.threshold` as const, {
              required: 'Threshold is required',
              validate: (value: number) => value > 0 || 'Threshold is required',
              setValueAs: (value: string) => Number(value),
            })}
            errorMessage={indexError?.threshold?.message}
          />
        </div>
      </div>
    </div>
  );
};

export default ChipBayPercentage;
