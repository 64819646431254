import TextInput from '@/modules/components/form/TextInput';
import Textarea from '@/modules/components/form/Textarea';
import { ContainedButton } from '@/modules/components/form/Buttons';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector, useCurrentStore } from '@/common';
import { createHelpdeskTicket, type Ticket } from '@/stores/_stores/actions';
import { SpinnerIcon } from '@/common/assets/icons';
import { useCallback, useMemo } from 'react';
import { useJitsu } from '@jitsu/jitsu-react';
import SelectInput from '@/modules/components/form/SelectInput';
import { type Device, selectDevices } from '@/stores/devices';
import { useParams } from 'react-router-dom';

const HelpDeskForm = (): JSX.Element => {
  const currentStore = useCurrentStore();
  const dispatch = useAppDispatch();
  const { analytics } = useJitsu();
  const devices: Device[] = useAppSelector(selectDevices);

  // This information is used to pre-select the device in the form
  // Only available when using in kiosk interactive mode
  const { serialNumber: thisDeviceSerialNumber } = useParams();

  const deviceOptions = useMemo(() => devices.map((d) => ({ label: d.name, value: d.serialNumber })), [devices]);

  const {
    register,
    formState: { errors, isSubmitting, isDirty },
    handleSubmit,
  } = useForm<Ticket>({
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      position: '',
      issue: '',
      storeId: currentStore?.storeId ?? '',
      storeName: currentStore?.name ?? '',
      // TODO: create a category for NLAF dashboard in Zendesk
      category: '360001784935', // Reuse 'Submit Feedback' category
      serialNumber: thisDeviceSerialNumber,
    },
  });

  const onSubmit: SubmitHandler<Ticket> = useCallback(
    async (data: Ticket) => {
      const device = devices.find((d) => d.serialNumber === data.serialNumber);
      await dispatch(
        createHelpdeskTicket({ ...data, storeId: currentStore?.storeId ?? '', storeName: currentStore?.name ?? '', deviceName: device?.name ?? '' }),
      );
      analytics.track('help_ticketSubmitted', { site: { name: data.storeName, id: data.storeId } });
    },
    [dispatch, currentStore],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='space-y-2 font-normal text-emphasis-secondary'>
        <div className='text-[22px]'>Submit a help desk ticket</div>
        <div className='text-base'>If you require help or need to report a bug, please submit a help desk ticket.</div>
      </div>
      <div className='mt-10 space-y-8'>
        <TextInput
          label={<div className='min-w-32'>Your name</div>}
          className='min-w-[28rem]'
          inline
          errorUnderline
          errorMessage={errors.name?.message}
          {...register('name', { required: 'Name cannot be empty.' })}
        />
        <TextInput
          label={<div className='min-w-32'>Email</div>}
          className='min-w-[28rem]'
          inline
          errorUnderline
          errorMessage={errors.email?.message}
          {...register('email', {
            required: 'Email cannot be empty.',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Invalid email address',
            },
          })}
        />
        <TextInput label={<div className='min-w-32'>Phone</div>} className='min-w-[28rem]' inline {...register('phone')} />
        <TextInput label={<div className='min-w-32'>Your position</div>} className='min-w-[28rem]' inline {...register('position')} />
        <SelectInput label={<div className='min-w-32'>Screen</div>} className='min-w-[28rem]' options={deviceOptions} inline {...register('serialNumber')} />
        <Textarea
          label={<div className='min-w-32'>Your issue</div>}
          className='min-w-[28rem]'
          rows={5}
          placeholder='Describe what you are seeing'
          inline
          errorUnderline
          errorMessage={errors.issue?.message}
          {...register('issue', { required: 'Please describe the issue you are seeing.' })}
        />
        {/* Form control buttons */}
        <div className='mt-6 flex items-center justify-end gap-x-6'>
          <ContainedButton disabled={isSubmitting || !isDirty}>{isSubmitting ? <SpinnerIcon className='w-6 h-6' /> : 'Submit ticket'}</ContainedButton>
        </div>
      </div>
    </form>
  );
};

export default HelpDeskForm;
