import { v4 as uuidv4 } from 'uuid';
import {
  type DestinationDashboard,
  type DestinationEmail,
  type ConditionChipBayPercentage,
  type ConditionTimeInRoi,
  type MappedAction,
  type MappedTrigger,
  type ConditionTableStatus,
  ActionTypes,
  DestinationType,
  Comparator,
  ConditionTypes,
  ConditionTableStatusStatus,
  type TriggerOption,
} from '../../../stores/triggers/types';

export const getNewTrigger = (triggerId: string): MappedTrigger => ({
  id: triggerId,
  title: '',
  actions: [],
  conditions: [],
  enabled: true,
});

export const getNewConditionTableStatus = (): ConditionTableStatus => ({
  id: uuidv4(),
  metric: ConditionTypes.TABLE_STATUS,
  status: ConditionTableStatusStatus.DIRTY,
  tableId: undefined,
  duration: 0,
});

export const getNewConditionTimeInROI = (): ConditionTimeInRoi => ({
  id: uuidv4(),
  metric: ConditionTypes.TIME_IN_ROI,
  threshold: 0,
  comparator: Comparator.GREATER_THAN_OR_EQUAL,
  roiId: '',
  targetType: undefined,
});

export const getNewConditionChipBayId = (): ConditionChipBayPercentage => ({
  id: uuidv4(),
  metric: ConditionTypes.CHIP_BAY_PERCENTAGE,
  duration: 0,
  chipBayId: '',
  comparator: Comparator.GREATER_THAN_OR_EQUAL,
  threshold: 0,
  targetType: undefined,
});

export const getNewActionMonitor = (): MappedAction => ({
  id: uuidv4(),
  type: ActionTypes.MONITOR,
  actionItem: {
    id: uuidv4(),
    type: ActionTypes.MONITOR,
    eventType: '',
    cameraIds: undefined,
    preRoll: 60,
    postRoll: 60,
  },
  destinations: undefined,
});

export const getNewActionDashboard = (siteId: string, organisationId: string): MappedAction => ({
  id: uuidv4(),
  type: ActionTypes.NOTIFICATION,
  actionItem: {
    id: uuidv4(),
    type: ActionTypes.NOTIFICATION,
    title: '',
    description: '',
    ttl: 8,
    level: 'ALERT',
  },
  destinations: [],
});

export const getNewActionEmail = (siteId: string, organisationId: string): MappedAction => ({
  id: uuidv4(),
  type: ActionTypes.EMAIL,
  actionItem: {
    id: uuidv4(),
    type: ActionTypes.EMAIL,
    subject: '',
    body: '',
  },
  destinations: [],
});

export const getNewDestinationEmail = (email: string): DestinationEmail => ({
  id: uuidv4(),
  type: DestinationType.EMAIL,
  address: email,
});

export const getNewDestinationDashboard = (dashboardId: string, siteId: string, organisationId: string): DestinationDashboard => ({
  id: uuidv4(),
  type: DestinationType.DASHBOARD,
  siteId,
  organisationId,
  dashboardId,
});

export const insertEmptyOptionToDropDown = (options: TriggerOption[]): TriggerOption[] => [{ value: '', label: '' }, ...options];
