import React from 'react';
import TriggerForm from './edit';
import { useAppSelector } from '@/common';
import { selectIsTriggersLoading } from '@/stores/triggers';

const TriggerFormWrapper: React.FC = () => {
  const isLoading = useAppSelector(selectIsTriggersLoading);
  return isLoading ? <div /> : <TriggerForm />;
};

export default TriggerFormWrapper;
