import React, { useCallback, useState } from 'react';
import TriggerRow from './TriggerRow';
import Dropdown from '@/modules/components/form/Dropdown';
import { type SubmitHandler, useForm } from 'react-hook-form';
import TextInput from '@/modules/components/form/TextInput';
import { type Condition, type MappedTrigger } from '../../../stores/triggers/types';
import { conditionTypes } from '../common';
import { useAppDispatch } from '@/common';
import { deleteTrigger } from '@/stores/triggers/actions';
import { getSiteTriggers } from '@/stores/triggers';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import confirm from '@/modules/components/confirmDialog/ConfirmDialog';

interface NewDashboard {
  category: string;
  search: string;
}

interface TriggerTableProps {
  triggers: MappedTrigger[];
}

interface FormValues {
  category: string;
  search: string;
}

const filterItems = (items: MappedTrigger[], type?: string, lookup?: string): MappedTrigger[] => {
  if (isEmpty(type) && isEmpty(lookup)) {
    return items;
  }

  const compareStrings = (a: string, b: string): boolean => {
    return a.toLowerCase().includes(b.toLowerCase());
  };

  return items.filter((item) => {
    const conditionMatch = !isEmpty(type) ? item.conditions.some((condition: Condition) => condition.metric === type) : false;
    const alertMatch = !isEmpty(lookup) ? compareStrings(item.title, lookup as string) : false;
    return !isEmpty(type) && !isEmpty(lookup) ? conditionMatch && alertMatch : conditionMatch || alertMatch;
  });
};

const emptyFilterOption = { value: '', label: 'Select' };

const TriggerTable = (props: TriggerTableProps): JSX.Element => {
  const triggersInitialValue = props.triggers;
  const [triggers, setTriggers] = useState<MappedTrigger[]>(triggersInitialValue);
  const { storeId } = useParams();
  const dispatch = useAppDispatch();

  const { register, handleSubmit, getValues } = useForm<NewDashboard>({
    defaultValues: {
      category: emptyFilterOption.value,
      search: '',
    },
    reValidateMode: 'onSubmit',
  });

  const onSubmit: SubmitHandler<FormValues> = useCallback(
    async (data) => {
      const formValues = getValues();
      setTriggers(filterItems(triggersInitialValue, formValues.category, formValues.search));
    },
    [],
  );

  const removeTrigger = useCallback(
    async (triggerId: string) => {

      const isConfirmed = (await confirm({
        title: 'Delete trigger',
        message: (
          <div className='grid grid-cols-1 gap-y-4'>
            <span className='flex justify-center'>Deleting this trigger will remove all the associated alerts</span>
            <span className='flex justify-center'>Do you want to do this?</span>
          </div>
        ),
      })) as boolean;
  
      if (!isConfirmed) {
        return;
      }

      const result = await dispatch(deleteTrigger({ triggerId })).unwrap();
      
      if (result?.statusCode === 200) {
        dispatch(getSiteTriggers({ storeId: storeId as string }));
      }
    },
    [triggers],
  );

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className='flex flex-row'>
        <div className='flex flex-row p-4 w-full justify-between'>
          <div className='flex flex-row w-full'>
            <Dropdown
              className='flex-none w-40 mr-4'
              inline={false}
              options={[emptyFilterOption, ...conditionTypes]}
              {...register('category')}
            />
            <div className='flex-auto ml-2'>
              <TextInput
                className='w-3/4'
                inline={false}
                placeholder='Search'
                {...register('search')}
              />
            </div>
          </div>
          <div className='pt-2'>
            <button
              type='submit'
              className='px-4 py-2.5 text-sm font-medium text-white  rounded-md focus:outline-none focus:ring-2 bg-button-primary hover:bg-button-primary-hover'
            >
              Search
            </button>
          </div>
        </div>
      </form>

      <div className='p-4'>
        {triggers.length === 0 ? (
          <div className='p-10 mt-[100px] text-center'>
            <p className='mb-[50px]'>No results for this search</p>
          </div>
        ) : (
          triggers.map((trigger) => <TriggerRow key={trigger.id} id={trigger.id} enabled={trigger.enabled} title={trigger.title} removeTrigger={removeTrigger} />)
        )}
      </div>
    </>
  );
};

export default TriggerTable;
