import { Outlet, useNavigate } from 'react-router-dom';
import { useEffect, useRef } from 'react';

const FullScreenLayout = (): JSX.Element => {
  const mainRef = useRef<HTMLElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (mainRef.current !== null) {
      void mainRef.current.requestFullscreen();
    }
  }, [mainRef]);

  return (
    <main ref={mainRef}>
      <div className='absolute top-0 right-0 z-50'>
        <button
          type='button'
          className='text-gray-400 bg-transparent rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center hover:text-gray-700'
          data-modal-hide='default-modal'
          onClick={() => {
            navigate(-1);
          }}
        >
          <svg className='w-3 h-3' aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 14 14'>
            <path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6' />
          </svg>
          <span className='sr-only'>Close</span>
        </button>
      </div>
      <Outlet />
    </main>
  );
};

export default FullScreenLayout;
