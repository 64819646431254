import React from 'react';
import { type UseFieldArrayRemove } from 'react-hook-form';
import TextInput from '@/modules/components/form/TextInput';
import { RemoveIcon } from '@/common/assets/icons';
import Textarea from '@/modules/components/form/Textarea';
import DashboardList from '../../components/DashboardList';
import { useFormContext } from 'react-hook-form';

interface DestinationDashboardProps {
  index: number;
  removeAction: UseFieldArrayRemove;
}

const DestinationDashboard: React.FC<DestinationDashboardProps> = ({ index, removeAction }) => {
  const {
    register,
    formState: { errors },
  }: any = useFormContext();
  const indexError = errors?.actions?.[index];
  return (
    <div className='flex space-x-4 space-y-4'>
      <div className='flex flex-row w-12 py-2.5 justify-center'>
        <button
          type='button'
          onClick={() => {
            removeAction(index);
          }}
          className='mt-11 text-emphasis-primary flex flex-row'
        >
          <RemoveIcon />
        </button>
      </div>
      <div className={`flex flex-row w-full pb-4 ${index !== 0 && 'border-t border-emphasis-base'}`}>
        <div className='flex flex-col py-10 w-40'>
          <div className='text-md text-emphasis-base'>Dashboard</div>
        </div>
        <div className='flex flex-col space-y-5 py-2.5'>
          <input type='hidden' {...register(`actions.${index}.id` as const)} />
          <input type='hidden' {...register(`actions.${index}.type` as const)} />
          <input type='hidden' {...register(`actions.${index}.actionItem.id` as const)} />
          <input type='hidden' {...register(`actions.${index}.actionItem.type` as const)} />
          <div className='flex flex-1 py-2'>
            <DashboardList index={index} />
          </div>
          <div className='flex flex-1 py-2'>
            <TextInput
              className='w-72'
              inline={false}
              label={'Alert Title'}
              errorUnderline={true}
              {...register(`actions.${index}.actionItem.title` as const, {
                required: 'Alert title is required',
                validate: (value: string) => (value.length > 0 && value.length <= 17) || 'Alert title is required, and must be 17 characters or fewer',
              })}
              errorMessage={indexError?.actionItem?.title?.message}
            />
          </div>
          <div className='flex flex-1 py-2 pb-3'>
            <Textarea
              className='w-72'
              inline={false}
              rows={5}
              errorUnderline={true}
              label={'Alert Description'}
              {...register(`actions.${index}.actionItem.description` as const, {
                required: 'Alert description is required',
                validate: (value: string) => (value.length > 0 && value.length <= 33) || 'Alert description is required, and must be 33 characters or fewer',
              })}
              errorMessage={indexError?.actionItem?.description?.message}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DestinationDashboard;
