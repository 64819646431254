import { type Option } from '@/modules/components/form/Dropdown';
import { ConditionTypes, MonitorEvents, Comparator, ActionTypes, ConditionTableStatusStatus } from '../../stores/triggers/types';

export const statusOptions = [
  {
    label: 'Dirty',
    value: ConditionTableStatusStatus.DIRTY,
  },
  {
    label: 'In Use',
    value: ConditionTableStatusStatus.IN_USE,
  },
  {
    label: 'Clean',
    value: ConditionTableStatusStatus.CLEAN,
  },
];

export const comparatorOptions: Array<Option<Comparator>> = [
  {
    label: 'Less than or equal',
    value: Comparator.LESS_THAN_OR_EQUAL,
  },
  {
    label: 'Greater than or equal',
    value: Comparator.GREATER_THAN_OR_EQUAL,
  },
  {
    label: 'Equal',
    value: Comparator.EQUAL,
  },
];

export const destinationMonitorEventTypeOptions: Array<Option<MonitorEvents>> = [
  {
    value: MonitorEvents.TIME_IN_INGRESS,
    label: 'Time in Ingress',
  },
  {
    value: MonitorEvents.TIME_IN_ORDER,
    label: 'Time in Order',
  },
  {
    value: MonitorEvents.TIME_IN_WINDOW,
    label: 'Time in Window',
  },
];

export const conditionTypes: Array<Option<ConditionTypes>> = [
  { value: ConditionTypes.TIME_IN_ROI, label: 'Time in Activity Zone' },
  { value: ConditionTypes.CHIP_BAY_PERCENTAGE, label: 'Chip Bay Percentage' },
  { value: ConditionTypes.TABLE_STATUS, label: 'Table Status' },
];

export const actionTypes: Array<Option<ActionTypes>> = [
  {
    label: 'Dashboard',
    value: ActionTypes.NOTIFICATION,
  },
  {
    label: 'Monitor',
    value: ActionTypes.MONITOR,
  },
  {
    label: 'Email',
    value: ActionTypes.EMAIL,
  },
];
