import { DeleteIcon } from '@/common/assets/icons';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface TriggerRowProps {
  id: string;
  title?: string;
  enabled?: boolean;
  removeTrigger: (id: string) => void;
}

const TriggerRow: React.FC<TriggerRowProps> = ({ title = 'no title', enabled, id, removeTrigger }) => {
  const navigate = useNavigate();

  return (
    <div className='flex items-center space-x-2'>
      <button
        className={`group flex w-full justify-between rounded-lg p-2.5 px-4 mb-2.5 border border-emphasis-base hover:border-white hover:svg-fill-red bg-white text-emphasis-base  hover:bg-button-primary hover:text-white`}
        onClick={(e) => {
          e.preventDefault();
          navigate(`../edit/${id}`);
        }}
      >
        <div className='flex-1 text-left'>{title}</div>
        <div className='flex-1 text-left'>
          <span className={enabled ?? false ? 'color-indicator-okay' : ''}>{enabled ?? false ? 'Active' : 'Inactive'}</span>
        </div>
      </button>
      <button
        className='text-emphasis-primary group-hover:text-white mb-2'
        onClick={(e) => {
          e.preventDefault();
          removeTrigger(id);
        }}
      >
        <DeleteIcon className='w-6 h-6 fill-current' />
      </button>
    </div>
  );
};

export default TriggerRow;
