import { useAppSelector } from '@/common';
import { LeftArrowIcon } from '@/common/assets/icons';
import { selectDashboard } from '@/stores/dashboards';
import { NavLink, useParams } from 'react-router-dom';
import Dashboard from '../dashboard';

const DashboardPreview = (): JSX.Element => {
  const { dashboardId } = useParams();
  const selectedDashboard = useAppSelector(selectDashboard(dashboardId));

  return (
    <div className='4xl:pl-10'>
      <NavLink className='min-w-0 flex-auto' to='..'>
        <div className='flex items-center gap-x-3 text-2xl text-[#666666]'>
          <LeftArrowIcon className='fill-emphasis-primary' /> <span className='font-semibold'>Preview</span> -
          <span className='font-light'> {selectedDashboard?.name}</span>
        </div>
      </NavLink>
      <div className='pt-5'>
        <Dashboard />
      </div>
    </div>
  );
};

export default DashboardPreview;
