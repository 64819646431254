import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useForm, useFieldArray, type SubmitHandler, FormProvider } from 'react-hook-form';
import { ActionTypes, type FormValues, ConditionTypes, type TriggerOption } from '../../../stores/triggers/types';
import { useNavigate, useParams, useLocation, useBlocker } from 'react-router-dom';
import TextInput from '@/modules/components/form/TextInput';
import Conditions from './conditions';
import Actions from './actions';
import { useAppDispatch, useAppSelector } from '@/common';
import { selectSiteTriggerById } from '@/stores/triggers';
import Switch from '../../components/form/Switch';
import {
  getNewActionDashboard,
  getNewActionEmail,
  getNewActionMonitor,
  getNewConditionChipBayId,
  getNewConditionTableStatus,
  getNewConditionTimeInROI,
  getNewTrigger,
} from './helpers';
import { updateTrigger } from '@/stores/triggers/actions';
import { ApplicationContext, FeatureFlags } from '@/types';
import confirm from '@/modules/components/confirmDialog/ConfirmDialog';
import { isArray } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useJitsu } from '@jitsu/jitsu-react';
import Spinner from '@/modules/components/spinner';
import { selectClientConfig } from '@/stores/clientConfigs';
import { type HistoryAction } from '@sentry/react/types/types';

type RouteHistoryType = {
  currentLocation: Location;
  nextLocation: Location;
  historyAction: HistoryAction;
} | null;

const TriggerForm: React.FC = () => {
  const { [FeatureFlags.eyecueAdminTriggerBuilderTemporaryIdMapping]: eyecueAdminTriggerBuilderTemporaryIdMapping } = useFlags();
  const { triggerId, storeId } = useParams();
  const applicationContext = useContext(ApplicationContext);
  const [shouldRenderForm, setShouldRenderForm] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const clientConfig = useAppSelector(selectClientConfig);
  const organisationName = useMemo(() => {
    return clientConfig?.name != null ? clientConfig?.name : '';
  }, [clientConfig]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { analytics } = useJitsu();
  const { loOrganisationId } = useMemo(() => {
    return {
      loOrganisationId: applicationContext.organization,
    };
  }, [applicationContext]);

  const thisTriggerId = useMemo(() => {
    return triggerId != null ? triggerId : uuidv4();
  }, [triggerId, location.pathname]);

  const siteTriggerSelector = useMemo(() => {
    return selectSiteTriggerById(thisTriggerId, storeId as string, loOrganisationId as string, organisationName ?? '');
  }, [thisTriggerId, storeId, loOrganisationId]);

  const siteTrigger = useAppSelector(siteTriggerSelector);

  const [enabled, setEnabled] = useState(siteTrigger?.trigger?.enabled ?? true);
  const [error, setError] = useState<null | string>(null);

  const { roiIds, chipBayIds, tableIds } = useMemo(() => {
    const roiIds = eyecueAdminTriggerBuilderTemporaryIdMapping?.roiIds;
    const chipBayIds = eyecueAdminTriggerBuilderTemporaryIdMapping?.chipBayIds;
    const tableIds = eyecueAdminTriggerBuilderTemporaryIdMapping?.tableIds;

    return {
      roiIds: (roiIds != null && isArray(roiIds) ? roiIds : []) as TriggerOption[],
      chipBayIds: (chipBayIds != null && isArray(chipBayIds) ? chipBayIds : []) as TriggerOption[],
      tableIds: (tableIds != null && isArray(tableIds) ? tableIds : []) as TriggerOption[],
    };
  }, [eyecueAdminTriggerBuilderTemporaryIdMapping]);

  useEffect(() => {
    if (siteTrigger?.siteId == null && !location.pathname.includes('/create')) {
      setError(`Unable to find the trigger ID ${triggerId}`);
      setShouldRenderForm(false);
    }
  }, [siteTrigger, triggerId]);

  useEffect(() => {
    const shouldRender = roiIds.length > 0 || chipBayIds.length > 0 || tableIds.length > 0;
    if (!shouldRender) {
      setError('This site setup is not completed yet, please contact your administrator.');
      setShouldRenderForm(false);
    }
  }, [roiIds, chipBayIds, tableIds]);

  const methods = useForm<FormValues>({
    defaultValues: {
      id: siteTrigger?.trigger?.id,
      title: siteTrigger?.trigger?.title ?? '',
      enabled: siteTrigger?.trigger?.enabled ?? true,
      organisationId: loOrganisationId,
      organisationName,
      conditions: siteTrigger?.trigger?.conditions,
      actions: siteTrigger?.trigger?.actions,
    },
  });

  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors, isDirty },
    setValue,
    getValues,
  } = methods;

  const [lastAttemptedLocation, setLastAttemptedLocation] = useState<RouteHistoryType>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useBlocker(
    useCallback(
      (tx: any) => {
        if (isDirty && !isModalOpen && !isLoading) {
          setIsModalOpen(true);
          setLastAttemptedLocation(tx);
          return true;
        } else {
          setIsModalOpen(false);
          setLastAttemptedLocation(null);
          return false;
        }
      },
      [isDirty, isModalOpen, isLoading],
    ),
  );

  useEffect(() => {
    if (isModalOpen) {
      confirm({
        title: 'Unsaved Changes',
        message: (
          <div className='grid grid-cols-1 gap-y-4'>
            <span className='flex justify-center'>You have unsaved changes.</span>
            <span className='flex justify-center'>Are you sure you want to leave without saving?</span>
          </div>
        ),
      })
        .then((isConfirmed: boolean) => {
          if (isConfirmed) {
            if (lastAttemptedLocation != null) {
              navigate(lastAttemptedLocation.nextLocation.pathname);
            }
          } else {
            setIsModalOpen(false);
            setLastAttemptedLocation(null);
          }
        })
        .catch(() => {
          setIsModalOpen(false);
          setLastAttemptedLocation(null);
        });
    }
  }, [isModalOpen, lastAttemptedLocation, navigate]);

  // Reset form on trigger change
  useEffect(() => {
    if (location.pathname.includes('/create')) {
      reset(getNewTrigger(thisTriggerId));
    }
  }, [location.pathname, thisTriggerId, reset]);

  const {
    fields: conditionFields,
    append: appendCondition,
    remove: removeCondition,
  } = useFieldArray({
    control,
    name: 'conditions',
  });

  const {
    fields: actionFields,
    append: appendAction,
    remove: removeAction,
  } = useFieldArray({
    control,
    name: 'actions',
  });

  const onSubmit: SubmitHandler<FormValues> = useCallback(
    async (data) => {
      setIsLoading(true);
      const formValues = getValues();
      if (formValues.conditions.length === 0) {
        setError('Please add at least one condition');
        return;
      }
      if (formValues.actions.length === 0) {
        setError('Please add at least one destination');
        return;
      }

      const trigger = {
        ...data,
      } as FormValues;

      const result = await dispatch(updateTrigger({ siteTrigger: trigger, siteId: storeId as string })).unwrap();

      if (result?.statusCode === 200) {
        navigate(`../all`);
        setError(null);
        analytics.track('triggers_triggerUpdated', { trigger: { title: trigger.title, enabled: trigger.enabled } });
      } else {
        setError(result?.error);
      }
      setIsLoading(false);
    },
    [storeId, loOrganisationId, enabled, organisationName],
  );

  const addCondition = useCallback((type: string) => {
    switch (type) {
      case ConditionTypes.TIME_IN_ROI:
        appendCondition(getNewConditionTimeInROI());
        break;
      case ConditionTypes.CHIP_BAY_PERCENTAGE:
        appendCondition(getNewConditionChipBayId());
        break;
      case ConditionTypes.TABLE_STATUS:
        appendCondition(getNewConditionTableStatus());
        break;
      default:
        break;
    }
  }, []);

  const addAction = useCallback(
    (type: string) => {
      switch (type) {
        case ActionTypes.MONITOR:
          appendAction(getNewActionMonitor());
          break;
        case ActionTypes.NOTIFICATION:
          appendAction(getNewActionDashboard(storeId as string, loOrganisationId as string));
          break;
        case ActionTypes.EMAIL:
          appendAction(getNewActionEmail(storeId as string, loOrganisationId as string));
          break;
        default:
          break;
      }
    },
    [storeId, loOrganisationId],
  );

  const resetForm = useCallback(async () => {
    const isConfirmed = (await confirm({
      title: 'Reset Trigger Form',
      message: (
        <div className='grid grid-cols-1 gap-y-4'>
          <span className='flex justify-center'>Resetting this form will remove all the applied changes</span>
          <span className='flex justify-center'>Do you want to do this?</span>
        </div>
      ),
    })) as boolean;

    if (!isConfirmed) {
      return;
    }
    reset();
  }, [thisTriggerId, reset]);

  return isLoading ? (
    <Spinner hide={!isLoading} />
  ) : (
    <FormProvider {...methods}>
      {error != null && (
        <div className='inline-flex justify-between bg-red-100 border border-status-error text-status-error px-4 py-3 my-2 rounded' role='alert'>
          <span className='block sm:inline pl-2'>
            <strong className='font-bold'>Error</strong>
            <br />
            {error}
          </span>
        </div>
      )}
      {shouldRenderForm && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <input type='hidden' {...register('id')} />
          <input type='hidden' {...register('organisationId')} />
          <input type='hidden' {...register('organisationName')} />
          <input type='hidden' {...register('enabled')} />
          <div className='flex justify-between items-center py-6'>
            <TextInput
              className='w-72'
              inline={false}
              errorUnderline={true}
              label='Trigger name'
              {...register('title', {
                required: 'Trigger name is required',
                validate: (value) => value.length <= 50 || 'Trigger name must be 50 characters or fewer',
              })}
              errorMessage={errors?.title?.message}
            />
            <Switch
              labelLeft='Disabled'
              lableRight='Enabled'
              name='enabled'
              value={enabled}
              onChange={(value) => {
                setEnabled(value);
                setValue('enabled', value, { shouldDirty: true });
              }}
            />
          </div>
          <Conditions
            conditionFields={conditionFields}
            removeCondition={removeCondition}
            addCondition={addCondition}
            roiIds={roiIds}
            chipBayIds={chipBayIds}
            tableIds={tableIds}
          />
          <Actions fields={actionFields} addAction={addAction} removeAction={removeAction} />
          <div className='flex justify-end space-x-4 mt-4'>
            <button type='button' onClick={resetForm} className='px-4 py-2 bg-button-secondary hover:bg-button-secondary-hover text-white rounded'>
              Reset
            </button>
            <button type='submit' className='px-4 py-2 bg-button-primary hover:bg-button-primary-hover text-white rounded'>
              Save
            </button>
          </div>
        </form>
      )}
    </FormProvider>
  );
};

export default TriggerForm;
