import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { type DestinationEmail } from '../../../stores/triggers/types';
import { getNewDestinationEmail } from '../edit/helpers';
import TextInput from '@/modules/components/form/TextInput';
import { AddIcon, RemoveIcon } from '@/common/assets/icons';
import { isEmpty } from 'lodash';

interface DestinationEmailProps {
  index: number;
}

const validateEmail = (email: string): boolean => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

const DashboardList: React.FC<DestinationEmailProps> = ({ index }) => {
  const name = `actions.${index}.destinations` as any;
  const [emailInput, setEmailInput] = useState('');
  const {
    register,
    formState: { errors },
    setError,
    setValue,
    getValues,
  }: any = useFormContext();
  const [emailList, setEmailList] = useState<DestinationEmail[]>(getValues(name));

  useEffect(() => {
    setValue(name, emailList, { shouldDirty: true });
  }, [emailList]);

  const handleAddEmail = (): void => {
    if (isEmpty(emailInput)) {
      return;
    }
    if (validateEmail(emailInput)) {
      setEmailList([...emailList, getNewDestinationEmail(emailInput)]);
      setEmailInput('');
      setError(name, { type: 'manual', message: '' });
    } else {
      setError(name, {
        type: 'manual',
        message: 'Invalid email address',
      });
    }
  };

  const handleDeleteEmail = (emailToRemove: string): void => {
    setEmailList(emailList.filter((email) => email.address !== emailToRemove));
  };

  const errorDestination: any = errors?.actions?.[index]?.destinations?.message;

  return (
    <div className='mb-4 flex space-x-8'>
      <div className='flex space-x-2'>
        <TextInput
          className='w-56'
          inline={false}
          label={'Email Addresses'}
          errorUnderline={true}
          value={emailInput}
          onBlur={() => {
            handleAddEmail();
          }}
          onChange={(e) => {
            if (!isEmpty(e.target.value)) {
              setEmailInput(e.target.value);
            }
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleAddEmail();
            }
          }}
          errorMessage={errorDestination}
        />
        <input
          type='hidden'
          {...register(name, {
            validate: (value: any) => value.length > 0 || 'Enter at least one email address',
          })}
        />
        <button type='button' className='mt-8' onClick={handleAddEmail}>
          <AddIcon />
        </button>
      </div>
      <div className='flex pt-7'>
        <div className='absolute w-auto h-auto flex flex-wrap'>
          {emailList.map((email, index: React.Key | null | undefined) => (
            <div
              key={index}
              className='inline-flex h-8 mt-2 mr-2 ml-2 items-center border border-emphasis-base text-emphasis-base rounded-full px-3 text-sm shadow-none'
            >
              <span>{email.address}</span>
              <button
                className='ml-2'
                onClick={(e) => {
                  e.preventDefault();
                  handleDeleteEmail(email.address);
                }}
              >
                <RemoveIcon />
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DashboardList;
